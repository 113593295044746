const escape = (text: string) => {
    return `"${text.replace('"', '""')}"`
}

const newLine = (tokens: any[]) => {
    return `${tokens.map(t => escape(t.toString())).join(",")}`
}

/**
 * Generates and downloads a csv file for HS attendance.
 * @param fencerData A dictionary of fencer names to their attendance for each meet (P for present, 
 *             N/A if no attendance, A if absent)
 * @param meets The meet names
 */
export default function generateHSAttendanceCsv(fencerData: { [name: string]: string[] }, meets: string[]) {
    const header = newLine(["Name", "Total", "Percentage", ...meets]);

    const lines: string[] = [];
    lines.push(header);

    for (const [fencer, attendance] of Object.entries(fencerData)) {
        const tokens: any[] = [fencer];
        const totalAttended =
            fencerData[fencer].reduce((accumulator, attended) => accumulator += attended === 'P' ? 1 : 0, 0);
        tokens.push(totalAttended);
        tokens.push(`${(totalAttended / meets.length * 100).toFixed(0)}%`);
        tokens.push(...attendance);
        lines.push(newLine(tokens));
    }

    return lines.join('\n');
}