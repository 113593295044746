import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { useParams, useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiEye, mdiContentCopy, mdiCheck, mdiClose } from "@mdi/js";
import { CommonLoading } from "../../../components/Loading/Loading";
import { TbTTab, TbTTabView } from "../../../components/TabView/TabView";
import TbTPage from "../../../components/TbTPage/TbTPage";
import BoutFencerSection from "../../../components/BoutFencerSection/BoutFencerSection";
import { MedicalDialog } from "../../../components/MedicalDialog";
import {
    BoutSide,
    DualMeetSignatureKey,
    IBoutEvent,
    ID,
    IDualMeet,
    IDualMeet_DB,
    IDualMeetBout,
    IDualMeetBoutFencer,
    IDualMeetTeam,
    IExistingFencer,
    IWriteInFencer,
    UserFlag,
    Weapon
} from "../../../types";
import TextField from "@mui/material/TextField";

import "./DualMeetInfo.css";
import {
    boutWinner,
    clinchScoreFromBouts,
    getIndividualStatsForMeet,
    IndividualStatsObj,
    meetScoreByWeapon,
    meetScoreFromBouts,
    roundScoresFromBouts,
    TeamIndividualStats,
    weaponToSignatureKey
} from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Button,
    Stack,
    Modal,
    MenuItem,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItemButton,
    ListItem,
    CardActions,
    ListItemText,
    DialogActions,
    Switch,
    FormControlLabel,
    DialogContentText,
    Drawer,
    Divider,
    ListItemIcon,
    Alert,
    Collapse,
    FormLabel,
    FormControl,
    FormGroup,
    FormHelperText,
    Checkbox
} from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { useWindowSize } from "../../../hooks/window";
import { modalBoxStyle, modalBoxStyleNarrower } from "../../..";
import useDatabase from "../../../hooks/database";
import ErrorPage from "../NotFound/NotFound";
import { exportHSDualMeet } from "../../../utils/pdfExport";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DBResult, isSuccess } from "../../../utils/database";
import { error } from "pdf-lib";
import { ERROR_DUAL_MEET_DOES_NOT_EXIST } from "../../../utils/constants";
import SnackbarError from "../../../components/SnackbarError";
import BoutLog from "../../../components/BoutLog/BoutLog";
import DualMeetSummary from "../../../components/DualMeetSummary/DualMeetSummary";
import HighSchoolDropdown from "../../../components/HighSchoolDropdown/HighSchoolDropdown";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
    })
}));

function LiveNowBout({ data, onClick }: { data: IDualMeetBout; onClick: () => void }) {
    return (
        <div className="liveNowBout" onClick={onClick}>
            <h4 style={{ fontWeight: 500 }}>
                <Icon path={mdiEye} size={1} color="#F00" /> Live now - {data.weapon} Round #{Math.floor(data.order / 3) + 1} Bout #
                {(data.order % 3) + 1}
            </h4>
            <h6>
                {data.fencer1.fencerInfo.firstName} {data.fencer1.fencerInfo.lastName} ({data.fencer1.strip || "Sub"}) vs{" "}
                {data.fencer2.fencerInfo.firstName} {data.fencer2.fencerInfo.lastName} ({data.fencer2.strip || "Sub"})
            </h6>
            <h6>
                Score: {data.fencer1.score}-{data.fencer2.score}
            </h6>
        </div>
    );
}

interface SummaryProps {
    score1: number;
    score2: number;
    timeouts1: number;
    timeouts2: number;
    squadScores1: Record<Weapon, number>;
    squadScores2: Record<Weapon, number>;
    bouts: Record<Weapon, IDualMeetBout[]>;
    setWeapon: (v: Weapon | "All") => void;
}

const SummaryComponent = ({ score1, score2, timeouts1, timeouts2, squadScores1, squadScores2, bouts, setWeapon }: SummaryProps) => {
    return (
        <Card
            sx={{
                maxWidth: 500,
                width: "90vw",
                margin: "auto",
                height: useWindowSize().width < 1000 ? "100%" : 300,
                borderRadius: 3
            }}
        >
            <CardHeader
                title="Dual Meet Summary"
                sx={{
                    backgroundColor: theme => (theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.secondary.dark),
                    fontWeight: "bold !important",
                    height: "60px"
                }}
            />
            <CardContent sx={{ padding: "0 !important", height: "100%" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%"
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                padding: "8px 10px",
                                width: "calc(50% - 1px)",
                                borderRight: "1px solid #999"
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 18, cursor: "pointer" }}
                                onClick={() => setWeapon("All")}
                                variant="subtitle1"
                                color="text.primary"
                            >
                                Overall Score:{" "}
                                <span className="avoidWrap">
                                    {score1} - {score2}
                                </span>
                            </Typography>
                        </div>
                        <div style={{ padding: "8px 10px", width: "50%" }}>
                            <Typography
                                sx={{ fontSize: 18, cursor: "pointer" }}
                                onClick={() => setWeapon("All")}
                                variant="subtitle1"
                                color="text.primary"
                            >
                                Total Timeouts:{" "}
                                <span className="avoidWrap">
                                    {timeouts1} - {timeouts2}
                                </span>
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                padding: "8px 10px",
                                width: "calc(50% - 1px)",
                                borderRight: "1px solid #999"
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 18, cursor: "pointer" }}
                                onClick={() => setWeapon("Sabre")}
                                variant="subtitle1"
                                color="text.primary"
                            >
                                Sabre Score:{" "}
                                <span className="avoidWrap">
                                    {squadScores1.Sabre} - {squadScores2.Sabre}
                                </span>
                            </Typography>
                        </div>
                        <div style={{ padding: "8px 10px", width: "50%" }}>
                            <Typography
                                sx={{ fontSize: 18, cursor: "pointer" }}
                                onClick={() => setWeapon("Sabre")}
                                variant="subtitle1"
                                color="text.primary"
                            >
                                Sabre Timeouts:{" "}
                                <span className="avoidWrap">
                                    {bouts.Sabre.reduce((acc, cur) => acc + +cur.fencer1.timeout, 0)} -{" "}
                                    {bouts.Sabre.reduce((acc, cur) => acc + +cur.fencer2.timeout, 0)}
                                </span>
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                padding: "8px 10px",
                                width: "calc(50% - 1px)",
                                borderRight: "1px solid #999"
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 18, cursor: "pointer" }}
                                onClick={() => setWeapon("Foil")}
                                variant="subtitle1"
                                color="text.primary"
                            >
                                Foil Score:{" "}
                                <span className="avoidWrap">
                                    {squadScores1.Foil} - {squadScores2.Foil}
                                </span>
                            </Typography>
                        </div>
                        <div style={{ padding: "8px 10px", width: "50%" }}>
                            <Typography
                                sx={{ fontSize: 18, cursor: "pointer" }}
                                onClick={() => setWeapon("Foil")}
                                variant="subtitle1"
                                color="text.primary"
                            >
                                Foil Timeouts:{" "}
                                <span className="avoidWrap">
                                    {bouts.Foil.reduce((acc, cur) => acc + +cur.fencer1.timeout, 0)} -{" "}
                                    {bouts.Foil.reduce((acc, cur) => acc + +cur.fencer2.timeout, 0)}
                                </span>
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                padding: "8px 10px",
                                width: "calc(50% - 1px)",
                                borderRight: "1px solid #999"
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 18, cursor: "pointer" }}
                                onClick={() => setWeapon("Epee")}
                                variant="subtitle1"
                                color="text.primary"
                            >
                                Epee Score:{" "}
                                <span className="avoidWrap">
                                    {squadScores1.Epee} - {squadScores2.Epee}
                                </span>
                            </Typography>
                        </div>
                        <div style={{ padding: "8px 10px", width: "50%" }}>
                            <Typography
                                sx={{ fontSize: 18, cursor: "pointer" }}
                                onClick={() => setWeapon("Epee")}
                                variant="subtitle1"
                                color="text.primary"
                            >
                                Epee Timeouts:{" "}
                                <span className="avoidWrap">
                                    {bouts.Epee.reduce((acc, cur) => acc + +cur.fencer1.timeout, 0)} -{" "}
                                    {bouts.Epee.reduce((acc, cur) => acc + +cur.fencer2.timeout, 0)}
                                </span>
                            </Typography>
                        </div>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        <div
                            style={{
                                height: "100%",
                                width: "calc(50% - 1px)",
                                borderRight: "1px solid #999"
                            }}
                        ></div>
                        <div style={{ height: "100%", width: "50%" }}></div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

interface IndividualStatsProps {
    weapon: Weapon | "All";
    stats: Record<"team1" | "team2", Record<string, { name: string; score: [number, number] }>>;
    team1Info: IDualMeetTeam | null;
    team2Info: IDualMeetTeam | null;
}

const homeStrips = {
    A: 1,
    B: 2,
    C: 3,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6
};

const awayStrips = {
    A: 4,
    B: 5,
    C: 6,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6
};

const WeaponScores = ({ scores }: { scores: Record<Weapon, [number, number]> }) => {
    return (
        <TableContainer component={Paper} sx={{ marginBottom: "15px" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Weapon Scores</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>Sabre Wins</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>Sabre Losses</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Home
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Sabre[0]}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Sabre[1]}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Away
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Sabre[1]}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Sabre[0]}</TableCell>
                    </TableRow>
                </TableBody>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell sx={{ textAlign: "center" }}>Foil Wins</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>Foil Losses</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Home
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Foil[0]}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Foil[1]}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Away
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Foil[1]}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Foil[0]}</TableCell>
                    </TableRow>
                </TableBody>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell sx={{ textAlign: "center" }}>Epee Wins</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>Epee Losses</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Home
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Epee[0]}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Epee[1]}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Away
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Epee[1]}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{scores.Epee[0]}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const IndividualStatsTable = ({ home, weapon, stats }: { home: boolean; weapon: Weapon; stats: IndividualStatsObj }) => {
    const entries = Object.entries(stats);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {home ? "Home" : "Away"}, {weapon}
                        </TableCell>
                        <TableCell sx={{ width: "50px" }}>W</TableCell>
                        <TableCell sx={{ width: "50px" }}>L</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {entries.map(([id, data]) => (
                        <TableRow key={`individualStat${id}`}>
                            <TableCell>{data.name}</TableCell>
                            <TableCell sx={{ width: "50px" }}>{data.score[0]}</TableCell>
                            <TableCell sx={{ width: "50px" }}>{data.score[1]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

interface BoutsListProps {
    bouts: Record<Weapon, IDualMeetBout[]>;
    boutsRaw: IDualMeetBout[];
    runningScores: ([number, number] | null)[];
    weapon: Weapon | "All";
    setCurrentBoutID: React.Dispatch<React.SetStateAction<string | null>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tabsRef?: React.MutableRefObject<any>;
}

const BoutsList = ({ bouts, boutsRaw, runningScores, weapon, setCurrentBoutID, tabsRef }: BoutsListProps) => {
    const bgOptions = {
        Sabre: "rgba(30, 89, 144, 0.5)",
        Foil: "rgba(190, 150, 27, 0.5)",
        Epee: "rgba(186, 186, 186, 0.5)"
    };

    const boutsOfWeapon = weapon === "All" ? boutsRaw : Object.values(bouts[weapon] ?? {});

    return (
        <Box>
            {boutsOfWeapon.map((bout, idx) => {
                return (
                    <div key={`boutOfWeapon${bout.id}`} style={{ backgroundColor: bgOptions[bout.weapon] }}>
                        {idx % 3 === 0 ? (
                            <div
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                                    color: "#FFF",
                                    fontSize: "1em",
                                    padding: "6px 4px 4px 4px"
                                }}
                            >
                                {weapon === "All" ? bout.weapon : ""} Round {Math.floor(bout.order / 3) + 1}
                            </div>
                        ) : null}
                        <div
                            key={`adsjfirjgi${idx}`}
                            className="boutInViewerList"
                            onClick={() => {
                                setCurrentBoutID(bout.id);
                                tabsRef?.current?.switchTab(2);
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 10,
                                    left: 10,
                                    fontSize: 48,
                                    width: 40,
                                    textAlign: "right"
                                }}
                            >
                                {homeStrips[bout.fencer1.strip]}
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 10,
                                    right: 10,
                                    fontSize: 48,
                                    width: 40,
                                    textAlign: "left"
                                }}
                            >
                                {awayStrips[bout.fencer2.strip]}
                            </div>
                            <div
                                style={{
                                    width: "50%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}
                            >
                                <div style={{ marginBottom: "10px" }}>
                                    {bout.fencer1.fencerInfo.firstName} {bout.fencer1.fencerInfo.lastName}
                                </div>
                                <div className="scoreBox" style={{ backgroundColor: "red" }}>
                                    {bout.fencer1.score}
                                </div>
                            </div>
                            <div
                                style={{
                                    width: "50%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}
                            >
                                <div style={{ marginBottom: "10px" }}>
                                    {bout.fencer2.fencerInfo.firstName} {bout.fencer2.fencerInfo.lastName}
                                </div>
                                <div className="scoreBox" style={{ backgroundColor: "green" }}>
                                    {bout.fencer2.score}
                                </div>
                            </div>
                            {runningScores[idx] ? (
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        left: 0,
                                        width: "100%",
                                        textAlign: "center",
                                        fontSize: 18
                                    }}
                                >
                                    {runningScores[idx]![0]} - {runningScores[idx]![1]}
                                </div>
                            ) : null}
                        </div>
                    </div>
                );
            })}
        </Box>
    );
};

export default function DualMeetInfo() {
    const { id } = useParams<{ id: string }>();

    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const [criticalError, setCriticalError] = useState("");
    const [snackbarError, setSnackbarError] = useState("");

    const [dualMeetInfo, setDualMeetInfo] = useState<IDualMeet | null>(null);
    const [correspondingDualMeetInfo, setCorrespondingDualMeetInfo] = useState<IDualMeet_DB | null>(null);
    const [team1Info, setTeam1Info] = useState<IDualMeetTeam | null>(null);
    const [team2Info, setTeam2Info] = useState<IDualMeetTeam | null>(null);
    const [boutsRaw, setBoutsRaw] = useState<IDualMeetBout[]>([]);
    const [weapon, setWeapon] = useState<Weapon | "All">("All");
    const [currentBoutID, setCurrentBoutID] = useState<string | null>(null);
    const [currentBoutLog, setCurrentBoutLog] = useState<IBoutEvent[]>([]);
    const [medicalInfoOpen, setMedicalInfoOpen] = useState(false);
    const [medicalInfoSide, setMedicalInfoSide] = useState<"left" | "right">("left");

    const [publishing, setPublishing] = useState(false);
    const [viewingPins, setViewingPins] = useState(false);
    const [showingPINInput, setShowingPINInput] = useState(false);
    const [enteringSignaturePIN, setEnteringSignaturePIN] = useState(false);
    const [enteringAttendancePIN, setEnteringAttendancePIN] = useState(false);
    const [enteredPIN, setEnteredPIN] = useState<number | null>(null);
    const [PINErrorMessage, setPINErrorMessage] = useState("");
    const [endingMeet, setEndingMeet] = useState(false);
    const [deletingMeet, setDeletingMeet] = useState(false);
    const [exportingMeet, setExportingMeet] = useState(false);
    const [exportingInProgress, setExportingInProgress] = useState(false);
    const [repairingScore, setRepairingScore] = useState(false);

    const singleReferee = Boolean(dualMeetInfo?.singleReferee);
    const [configuringRefs, setConfiguringRefs] = useState(false);
    const [configuringSabre, setConfiguringSabre] = useState(false);
    const [configuringFoil, setConfiguringFoil] = useState(false);
    const [configuringEpee, setConfiguringEpee] = useState(false);
    const [configuringAll, setConfiguringAll] = useState(false);
    const [refNameSabre, setRefNameSabre] = useState("");
    const [refNameFoil, setRefNameFoil] = useState("");
    const [refNameEpee, setRefNameEpee] = useState("");
    const [refNameAll, setRefNameAll] = useState("");

    const [choosingSignature, setChoosingSignature] = useState(false);
    const [signing, setSigning] = useState(false);
    const [signingWeapon, setSigningWeapon] = useState<Weapon | "Home Team" | "Away Team" | "Meet Referee">("Sabre");
    const [signature, setSignature] = useState("");
    const meetRefereeSigned =
        Boolean(dualMeetInfo?.signatures?.sabreRef) &&
        Boolean(dualMeetInfo?.signatures?.foilRef) &&
        Boolean(dualMeetInfo?.signatures?.epeeRef);
    const existingSignature: string | undefined =
        signingWeapon === "Meet Referee"
            ? meetRefereeSigned
                ? dualMeetInfo?.signatures?.sabreRef
                : undefined
            : dualMeetInfo?.signatures?.[weaponToSignatureKey[signingWeapon]];
    const isTeamSignature = signingWeapon === "Home Team" || signingWeapon === "Away Team";
    const signatureTitle = `${signingWeapon}${!isTeamSignature ? " Referee" : ""} Signature`;

    const [editingAttendance, setEditingAttendance] = useState(false);
    const [currentSchoolAttendanceEdit, setCurrentSchoolAttendanceEdit] = useState<null | "Home Team" | "Away Team">(null);
    const [selectedFencers, setSelectedFencers] = useState<ID[]>([]);
    const [areAllFencersSelected, setAreAllFencersSelected] = useState(false);
    const [attendanceSignature, setAttendanceSignature] = useState("");

    const currentTeam = currentSchoolAttendanceEdit
        ? currentSchoolAttendanceEdit == "Home Team"
            ? dualMeetInfo?.team1
            : dualMeetInfo?.team2
        : undefined;

    const currentTeamEqualsSelectedFencers = currentTeam && currentTeam.attendance === selectedFencers;
    const selectedFencersIsEmpty = !selectedFencers || selectedFencers.length == 0;

    useMemo(() => {
        if (currentTeam) {
            if (!currentTeam.id) return;

            if (currentTeam.attendance && currentTeamEqualsSelectedFencers && currentTeam.attendanceSignature) {
                setSelectedFencers(currentTeam.attendance);
            } else if (selectedFencersIsEmpty) {
                const key = `attendance-${dualMeetInfo!.id}-${currentTeam.id}`;
                const saved = localStorage.getItem(key);

                if (saved) {
                    const parsed = JSON.parse(saved);
                    if (parsed.length > 0) {
                        setSelectedFencers(parsed);
                    }
                }
            }
        }
    }, [dualMeetInfo, currentTeam, currentTeamEqualsSelectedFencers, selectedFencersIsEmpty]);

    const existingAttendanceSignature: string | undefined =
        currentSchoolAttendanceEdit == "Home Team"
            ? dualMeetInfo?.team1?.attendanceSignature
            : currentSchoolAttendanceEdit == "Away Team"
              ? dualMeetInfo?.team2?.attendanceSignature
              : undefined;

    const [editingDate, setEditingDate] = useState(false);
    const [newDate, setNewDate] = useState(new Date());

    const [individualStats, setIndividualStats] = useState<TeamIndividualStats>({
        team1: { Sabre: {}, Foil: {}, Epee: {} },
        team2: { Sabre: {}, Foil: {}, Epee: {} }
    });

    const storeEnteredPIN = useSelector((s: ReduxState) => s.enteredPIN);
    const storeEnteredPINExpiration = useSelector((s: ReduxState) => s.enteredPINExpiration);

    const currentBoutIDRef = useRef<string | null>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tabsRef = useRef<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bottomRef = useRef<any>();

    const history = useHistory();
    const dispatch = useDispatch();
    const size = useWindowSize();
    const DB = useDatabase();

    const administratorOfTeam: "left" | "right" | null = (() => {
        if (!userInfo) return null;

        if (team1Info?.administrators?.includes(userInfo.id) || team1Info?.managers?.includes(userInfo.id)) {
            return "left";
        } else if (team2Info?.administrators?.includes(userInfo.id) || team2Info?.managers?.includes(userInfo.id)) {
            return "right";
        }
        return null;
    })();

    const superadmin = Boolean((userInfo?.flags || 0) & UserFlag.MeetManager);
    const canDeleteMeet = superadmin || (userInfo?.id ? dualMeetInfo?.createdBy === userInfo.id : false);
    const canManageMeet = superadmin || Boolean(administratorOfTeam);

    const handleDualMeet = (result: DBResult<IDualMeet>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        const { data } = result;
        setDualMeetInfo(data);
        setTeam1Info(data.team1);
        setTeam2Info(data.team2);
        setNewDate(new Date(data.startedAt));
    };

    useEffect(() => {
        DB.getDualMeet(id, handleDualMeet).then(result => {
            if (result.status === "fail") return setCriticalError(result.data);
            const { data } = result;
            Promise.all(
                data.bouts.map((l, idx) =>
                    DB.getBout(l.id, boutData => {
                        if (boutData.status === "fail") return;
                        setBoutsRaw(boutsRaw => {
                            if (!boutsRaw.length) return boutsRaw;
                            const copy = [...boutsRaw];
                            copy.splice(idx, 1, boutData.data);
                            DB.getBoutEventsFromArray(boutData.data.log || []).then(d => {
                                if (currentBoutIDRef.current === l.id) {
                                    setCurrentBoutLog(d.filter(isSuccess).map(l => l.data));
                                }
                            });
                            return copy;
                        });
                    })
                )
            ).then(bouts => setBoutsRaw(bouts.filter(isSuccess).map(l => l.data)));
        });
        return () => {
            DB.stopListeningDualMeet(id, handleDualMeet);
            for (const bout of dualMeetInfo?.bouts || []) {
                DB.stopListeningBout(bout.id);
            }
        };
    }, [id]);

    const checkRefNameAllUpdate = () => {
        if (
            dualMeetInfo?.sabreReferee &&
            dualMeetInfo?.sabreReferee === dualMeetInfo?.foilReferee &&
            dualMeetInfo?.sabreReferee === dualMeetInfo?.epeeReferee
        ) {
            setRefNameAll(dualMeetInfo?.sabreReferee);
        }
    };

    useEffect(() => {
        setRefNameSabre(dualMeetInfo?.sabreReferee || "");
        checkRefNameAllUpdate();
    }, [dualMeetInfo?.sabreReferee]);

    useEffect(() => {
        setRefNameFoil(dualMeetInfo?.foilReferee || "");
        checkRefNameAllUpdate();
    }, [dualMeetInfo?.foilReferee]);

    useEffect(() => {
        setRefNameEpee(dualMeetInfo?.epeeReferee || "");
        checkRefNameAllUpdate();
    }, [dualMeetInfo?.epeeReferee]);

    useEffect(() => {
        if (!dualMeetInfo?.correspondingMeet) return;
        DB.getDualMeetRaw(dualMeetInfo?.correspondingMeet).then(meet => {
            if (meet.status === "fail") return;
            // TODO: handle when corresponding meet deleted
            // if (meet.status === "fail") return setSnackbarError(meet.data);
            setCorrespondingDualMeetInfo(meet.data);
        });
    }, [dualMeetInfo?.correspondingMeet]);

    useEffect(() => {
        currentBoutIDRef.current = currentBoutID;
        const currentBout = boutsRaw.find(l => l.id === currentBoutID);
        if (currentBout) {
            Promise.all((currentBout.log || []).map(DB.getBoutEvent.bind(DB))).then((log: DBResult<IBoutEvent>[]) =>
                setCurrentBoutLog(log.filter(isSuccess).map(l => l.data))
            );
        }
    }, [currentBoutID]);

    useEffect(() => {
        const indivStats = getIndividualStatsForMeet(boutsRaw);
        setIndividualStats(indivStats);
    }, [boutsRaw]);

    const currentBout = boutsRaw.find(l => l.id === currentBoutID);

    const exportMeet = async () => {
        if (!dualMeetInfo) return;

        setExportingInProgress(true);

        const meet = dualMeetInfo;
        const womens = meet.team1.name.includes("Women") || meet.team2.name.includes("Women");

        const events = await Promise.all(boutsRaw.map(l => DB.getBoutEventsFromArray(l.log)));
        const eventsObj: Record<string, IBoutEvent[]> = {};
        for (let i = 0; i < events.length; i++) {
            eventsObj[boutsRaw[i].id] = events[i].filter(isSuccess).map(l => l.data);
        }

        const pdf = await exportHSDualMeet(dualMeetInfo, boutsRaw, eventsObj, womens ? "girls" : "boys");
        const pdfArray = await pdf.save();

        const blob = new Blob([pdfArray], { type: "application/pdf" });
        const link = window.URL.createObjectURL(blob);
        window.open(link);

        setExportingInProgress(false);
    };

    const setMeetPublished = () => {
        if (!dualMeetInfo?.published) {
            DB.publishDualMeet(id);
        }
    };

    const bouts = (() => {
        const res: Record<Weapon, IDualMeetBout[]> = {
            Sabre: [],
            Foil: [],
            Epee: []
        };

        for (const bout of boutsRaw) {
            res[bout.weapon].push(bout);
        }

        return res;
    })();

    const boutsOfWeapon = weapon === "All" ? boutsRaw : Object.values(bouts[weapon] ?? {});

    const [score1, score2] = meetScoreFromBouts(boutsRaw);
    const clinchScore = clinchScoreFromBouts(boutsRaw);
    const roundScores = roundScoresFromBouts(boutsRaw);
    const weaponScores = meetScoreByWeapon(boutsRaw);

    const timeouts1 = Object.values(bouts)
        .flat()
        .reduce((acc, cur) => acc + Number(cur?.fencer1?.timeout), 0);
    const timeouts2 = Object.values(bouts)
        .flat()
        .reduce((acc, cur) => acc + Number(cur?.fencer2?.timeout), 0);

    const squadScores1 = (() => {
        const scores = {
            Sabre: 0,
            Foil: 0,
            Epee: 0
        };
        for (const w in bouts) {
            for (const bout of bouts[w]) {
                if (boutWinner(bout) === BoutSide.Fencer1) {
                    scores[w]++;
                }
            }
        }
        return scores;
    })();

    const squadScores2 = (() => {
        const scores = {
            Sabre: 0,
            Foil: 0,
            Epee: 0
        };
        for (const w in bouts) {
            for (const bout of bouts[w]) {
                if (boutWinner(bout) === BoutSide.Fencer2) {
                    scores[w]++;
                }
            }
        }
        return scores;
    })();

    const liveBouts = boutsRaw.filter(l => l.startedAt && !l.endedAt);

    if (criticalError === ERROR_DUAL_MEET_DOES_NOT_EXIST) {
        return <ErrorPage code={404} message="The requested dual meet could not be found." />;
    } else if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    const onLiveNowBoutClick = (l: IDualMeetBout) => {
        setWeapon(l.weapon);
        setCurrentBoutID(l.id);
        tabsRef?.current?.switchTab(2);
        bottomRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const setClipboardPIN = () => {
        if (!administratorOfTeam) return;

        const PIN = administratorOfTeam === "left" ? dualMeetInfo?.pin1 : dualMeetInfo?.pin2;

        if (!PIN) return;

        navigator.clipboard.writeText(PIN.toString());
    };

    const tryShowingPINInput = (action: "signature" | "score" | "attendance") => {
        const success = () => {
            if (action === "signature") {
                setSigning(true);
            } else if (action === "attendance") {
                setEditingAttendance(true);
            } else {
                let url = `/dualMeetScorer/${id}`;
                if (currentBoutID) {
                    url += `?bout=${currentBoutID}`;
                }
                history.push(url);
            }
        };

        const showPin = () => {
            if (action === "signature") {
                setEnteringSignaturePIN(true);
            } else if (action === "attendance") {
                setEnteringAttendancePIN(true);
            }
            setShowingPINInput(true);
        };

        if (superadmin || administratorOfTeam !== null) {
            success();
        } else if ((storeEnteredPINExpiration || -Infinity) < Date.now()) {
            dispatch({ type: "resetEnteredPINV2" });
            showPin();
        } else if (storeEnteredPIN === dualMeetInfo?.pin1 || storeEnteredPIN === dualMeetInfo?.pin2) {
            success();
        } else {
            showPin();
        }
    };

    const tryPIN = () => {
        if (!enteredPIN) {
            return setPINErrorMessage("You must enter a PIN.");
        }

        if (enteredPIN.toString().length !== 5) {
            return setPINErrorMessage("The PIN must be 5 digits long.");
        }

        if (enteredPIN !== dualMeetInfo?.pin1 && enteredPIN !== dualMeetInfo?.pin2 && enteredPIN !== dualMeetInfo?.refereePin) {
            return setPINErrorMessage("The PIN you entered is incorrect.");
        }

        dispatch({ type: "setEnteredPINV2", payload: enteredPIN });
        if (enteringSignaturePIN) {
            setShowingPINInput(false);
            setSigning(true);
        } else if (enteringAttendancePIN) {
            setShowingPINInput(false);
            setEditingAttendance(true);
        } else {
            let url = `/dualMeetScorer/${id}`;
            if (currentBoutID) {
                url += `?bout=${currentBoutID}`;
            }
            history.push(url);
        }
    };

    const endMeet = (forfeit: "left" | "right" | "force") => {
        if (forfeit === "force" && ((administratorOfTeam && score1 + score2 === 27) || superadmin)) {
            DB.endDualMeet(id, forfeit);
        } else if (administratorOfTeam) {
            DB.endDualMeet(id, forfeit);
        }
    };

    const deleteMeet = async () => {
        setDualMeetInfo(null);
        setTimeout(async () => {
            await DB.deleteDualMeet(id);
            history.push(`/dualMeets`);
        }, 1000);
    };

    const repairScore = async () => {
        await DB.repairMeetScore(id);
        setRepairingScore(false);
    };

    const submitNewDate = async () => {
        if (!id || !newDate) return;
        await DB.setDualMeetDate(id, newDate);
        setEditingDate(false);
    };

    const saveReferee = async (weapon: Weapon | "All") => {
        const relevantName =
            weapon === "All" ? refNameAll : weapon === "Sabre" ? refNameSabre : weapon === "Foil" ? refNameFoil : refNameEpee;

        if (weapon === "All") {
            await DB.setMeetReferee(id, { Sabre: refNameAll, Foil: refNameAll, Epee: refNameAll });
        } else {
            await DB.setMeetReferee(id, { [weapon]: relevantName || null });
        }

        if (weapon === "Sabre") {
            setConfiguringSabre(false);
        }
        if (weapon === "Foil") {
            setConfiguringFoil(false);
        }
        if (weapon === "Epee") {
            setConfiguringEpee(false);
        }
        if (weapon === "All") {
            setConfiguringAll(false);
        }
    };

    const startSigning = (weapon: Weapon | "Home Team" | "Away Team" | "Meet Referee") => {
        let signed = false;
        if (weapon === "Meet Referee") {
            signed = meetRefereeSigned;
        } else {
            signed = Boolean(dualMeetInfo?.signatures?.[weaponToSignatureKey[weapon]]);
        }
        setSigningWeapon(weapon);
        tryShowingPINInput("signature");
    };

    const sign = async () => {
        if (!dualMeetInfo) return;
        const key = signingWeapon === "Meet Referee" ? "allRefs" : (weaponToSignatureKey[signingWeapon] as DualMeetSignatureKey);
        await DB.signMeet(dualMeetInfo.id, key, signature);
        setSigning(false);
        setSignature("");
    };

    const setSingleReferee = async (val: boolean) => {
        if (!dualMeetInfo) return;
        await DB.setSingleReferee(dualMeetInfo.id, val);
    };

    const saveSelectedFencersToLocalStorage = (selected: string[]) => {
        if (!dualMeetInfo || !currentTeam?.id) return;
        const key = `attendance-${dualMeetInfo.id}-${currentTeam.id}`;

        localStorage.setItem(key, JSON.stringify(selected));
    };

    const clearSelectedFencersInLocalStorage = () => {
        if (!dualMeetInfo || !currentTeam?.id) return;
        const key = `attendance-${dualMeetInfo.id}-${currentTeam.id}`;

        localStorage.removeItem(key);
    };

    const hideTeamAttendance = () => {
        setCurrentSchoolAttendanceEdit(null);
        setSelectedFencers([]);
        setAreAllFencersSelected(false);
    };

    const toggleSelectAllFencers = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAreAllFencersSelected(event.target.checked);
        if (event.target.checked) {
            const team = currentSchoolAttendanceEdit == "Home Team" ? dualMeetInfo?.team1 : dualMeetInfo?.team2;

            if (!team) return;

            const allFencers = [...team.fencers.Epee, ...team.fencers.Foil, ...team.fencers.Sabre].filter(
                f => "id" in f
            ) as IExistingFencer[];

            const selected = [...allFencers.map(f => f.id)];

            saveSelectedFencersToLocalStorage(selected);
            setSelectedFencers(selected);
        } else {
            clearSelectedFencersInLocalStorage();
            setSelectedFencers([]);
        }
    };

    const toggleFencerSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            selectedFencers.push(event.target.name);
            saveSelectedFencersToLocalStorage(selectedFencers);
            setSelectedFencers([...selectedFencers]);
        } else {
            const newSelectedFencers = [...selectedFencers.filter(fencerId => fencerId !== event.target.name)];
            saveSelectedFencersToLocalStorage(newSelectedFencers);
            setSelectedFencers(newSelectedFencers);
        }
    };

    const submitAttendance = async () => {
        if (!dualMeetInfo || selectedFencers.length == 0 || !attendanceSignature.trim() || !currentSchoolAttendanceEdit) return;

        const team = currentSchoolAttendanceEdit == "Home Team" ? "team1" : "team2";

        await DB.setAttendance(dualMeetInfo.id, team, [...new Set(selectedFencers)], attendanceSignature);

        setAttendanceSignature("");
        clearSelectedFencersInLocalStorage();
        hideTeamAttendance();
    };

    const leftFencer = currentBout?.switchedSides ? currentBout?.fencer2 : currentBout?.fencer1;
    const rightFencer = currentBout?.switchedSides ? currentBout?.fencer1 : currentBout?.fencer2;
    const medicalInfoFencer = medicalInfoSide === "left" ? leftFencer : rightFencer;

    const WeaponDropdown = () => {
        return (
            <HighSchoolDropdown displayText={weapon} enableElevation={true}>
                <MenuItem key="All" onClick={() => setWeapon("All")}>
                    All weapons
                </MenuItem>
                <MenuItem key="Sabre" onClick={() => setWeapon("Sabre")}>
                    Sabre
                </MenuItem>
                <MenuItem key="Foil" onClick={() => setWeapon("Foil")}>
                    Foil
                </MenuItem>
                <MenuItem key="Epee" onClick={() => setWeapon("Epee")}>
                    Epee
                </MenuItem>
            </HighSchoolDropdown>
        );
    };

    const bgOptions = {
        Sabre: "rgba(30, 89, 144, 0.5)",
        Foil: "rgba(190, 150, 27, 0.5)",
        Epee: "rgba(186, 186, 186, 0.5)"
    };

    const runningScores: ([number, number] | null)[] = [];
    const lastRunningScore = [0, 0];

    for (const bout of boutsOfWeapon) {
        const winner = boutWinner(bout);

        if (winner === BoutSide.Fencer1) {
            runningScores.push([lastRunningScore[0] + 1, lastRunningScore[1]]);
            lastRunningScore[0]++;
        } else if (winner === BoutSide.Fencer2) {
            runningScores.push([lastRunningScore[0], lastRunningScore[1] + 1]);
            lastRunningScore[1]++;
        } else {
            runningScores.push(null);
        }
    }

    const weaponIndividualStats =
        weapon === "All"
            ? {
                  team1: Object.values(individualStats.team1).reduce((acc, cur) => ({ ...acc, ...cur }), {}),
                  team2: Object.values(individualStats.team2).reduce((acc, cur) => ({ ...acc, ...cur }), {})
              }
            : {
                  team1: individualStats.team1[weapon],
                  team2: individualStats.team2[weapon]
              };

    const meetStarted = Date.now() > (dualMeetInfo?.startedAt || Infinity);
    const mobile = size.width < 1000;

    const FencerAttendanceList = ({
        weapon,
        allFencers
    }: {
        weapon: Weapon;
        allFencers: ((IWriteInFencer | IExistingFencer) & { home: number; away: number })[];
    }) => {
        const existingFencers = allFencers.filter(fencer => "id" in fencer) as IExistingFencer[];
        return (
            <>
                {existingFencers.length > 0 && <Divider>{weapon}</Divider>}
                {existingFencers.map((fencer, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <Checkbox
                                name={fencer.id}
                                checked={selectedFencers.includes(fencer.id)}
                                onChange={event => toggleFencerSelect(event)}
                            />
                        }
                        disabled={Boolean(existingAttendanceSignature)}
                        label={`${fencer.firstName} ${fencer.lastName}`}
                    />
                ))}
            </>
        );
    };

    const IndividualStats = ({ weapon, stats, team1Info, team2Info }: IndividualStatsProps) => {
        return (
            <Card
                sx={{
                    maxWidth: 500,
                    width: "90vw",
                    margin: "auto",
                    maxHeight: 300,
                    overflow: "auto",
                    borderRadius: 3
                }}
            >
                <CardHeader
                    title="Individual Stats"
                    sx={{
                        backgroundColor: theme => (theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.secondary.dark),
                        fontWeight: "bold !important",
                        height: "60px",
                        position: "relative"
                    }}
                    action={
                        <div
                            style={{
                                position: "absolute",
                                left: 12,
                                top: 11
                            }}
                        >
                            <WeaponDropdown />
                        </div>
                    }
                />
                <CardContent sx={{ padding: "0 !important", height: "auto" }}>
                    <div
                        style={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            color: "white",
                            fontSize: 18
                        }}
                    >
                        <div
                            style={{
                                minHeight: 230,
                                width: 299,
                                paddingBottom: 10,
                                borderRight: "1px solid #999",
                                overflowX: "hidden"
                            }}
                        >
                            <div
                                style={{
                                    padding: "10px 0",
                                    borderBottom: "1px solid #999"
                                }}
                            >
                                {team1Info?.abbreviation || "Unknown team"}
                            </div>
                            {Object.values(stats.team1).map((l, idx) => (
                                <p key={`individualStatsTeam1${l.name}${idx}`} style={{ margin: 10 }}>
                                    <span
                                        style={{
                                            maxWidth: "calc(100% - 5ch)",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            display: "inline-block",
                                            verticalAlign: "bottom"
                                        }}
                                    >
                                        {l.name}
                                    </span>
                                    : {l.score[0]}-{l.score[1]}
                                </p>
                            ))}
                        </div>
                        <div
                            style={{
                                minHeight: 230,
                                width: 300,
                                paddingBottom: 10
                            }}
                        >
                            <div
                                style={{
                                    padding: "10px 0",
                                    borderBottom: "1px solid #999"
                                }}
                            >
                                {team2Info?.abbreviation || "Unknown team"}
                            </div>
                            {Object.values(stats.team2).map((l, idx) => (
                                <p key={`individualStatsTeam2${l.name}${idx}`} style={{ margin: 10 }}>
                                    <span
                                        style={{
                                            maxWidth: "calc(100% - 5ch)",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            display: "inline-block",
                                            verticalAlign: "bottom"
                                        }}
                                    >
                                        {l.name}
                                    </span>
                                    : {l.score[0]}-{l.score[1]}
                                </p>
                            ))}
                        </div>
                    </div>
                </CardContent>
            </Card>
        );
    };

    return (
        <TbTPage className="dualMeetInfoPage">
            {dualMeetInfo ? (
                <>
                    <DualMeetSummary
                        info={dualMeetInfo}
                        score1={score1}
                        score2={score2}
                        canManageMeet={canManageMeet}
                        canDeleteMeet={canDeleteMeet}
                        correspondingMeet={correspondingDualMeetInfo}
                        editDate={() => setEditingDate(true)}
                        publish={() => setPublishing(true)}
                        chooseSignature={() => setChoosingSignature(true)}
                        editAttendance={() => tryShowingPINInput("attendance")}
                        exportMeet={() => setExportingMeet(true)}
                        configureRefs={() => setConfiguringRefs(true)}
                        viewPINs={() => setViewingPins(true)}
                        endMeet={() => setEndingMeet(true)}
                        deleteMeet={() => setDeletingMeet(true)}
                        repairScore={() => setRepairingScore(true)}
                    />
                    <div className="liveNowBoutsList">
                        {liveBouts.map(l => (
                            <LiveNowBout key={`liveNowBout${l.id}`} data={l} onClick={() => onLiveNowBoutClick(l)} />
                        ))}
                    </div>
                    {size.width > 1000 ? (
                        <>
                            <Stack
                                direction="row"
                                sx={{
                                    maxWidth: 1000,
                                    width: "90vw",
                                    margin: "auto"
                                }}
                                spacing={4}
                            >
                                <SummaryComponent
                                    score1={score1}
                                    score2={score2}
                                    timeouts1={timeouts1}
                                    timeouts2={timeouts2}
                                    squadScores1={squadScores1}
                                    squadScores2={squadScores2}
                                    bouts={bouts}
                                    setWeapon={setWeapon}
                                />
                                <IndividualStats
                                    weapon={weapon}
                                    stats={weaponIndividualStats}
                                    team1Info={team1Info}
                                    team2Info={team2Info}
                                />
                            </Stack>
                            <Card
                                sx={{
                                    maxWidth: 1000,
                                    width: "90vw",
                                    margin: "20px auto"
                                }}
                                ref={bottomRef}
                            >
                                <CardHeader
                                    title="Bouts Viewer"
                                    sx={{
                                        backgroundColor: theme =>
                                            theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.secondary.dark,
                                        fontWeight: "bold !important",
                                        height: "60px",
                                        position: "relative"
                                    }}
                                    action={
                                        <div
                                            style={{
                                                position: "absolute",
                                                left: 12,
                                                top: 11,
                                                display: "flex"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginRight: 12
                                                }}
                                            >
                                                {superadmin && currentBout ? (
                                                    <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        href={`/dualMeetScorer/${id}?bout=${currentBout.id}`}
                                                        className="dualMeetInfoEditBoutButton"
                                                    >
                                                        Edit
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() => tryShowingPINInput("score")}
                                                        color="secondary"
                                                        variant="contained"
                                                    >
                                                        Edit
                                                    </Button>
                                                )}
                                            </div>
                                            <div>
                                                <WeaponDropdown />
                                            </div>
                                        </div>
                                    }
                                />
                                <CardContent sx={{ padding: "0 !important" }}>
                                    <div style={{ display: "flex" }}>
                                        <div className="boutViewerList">
                                            <BoutsList
                                                bouts={bouts}
                                                boutsRaw={boutsRaw}
                                                runningScores={runningScores}
                                                setCurrentBoutID={setCurrentBoutID}
                                                weapon={weapon}
                                                tabsRef={tabsRef}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                backgroundColor: "rgba(0, 0, 0, 0.95)",
                                                textAlign: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                color: "white",
                                                flexGrow: "1",
                                                fontSize: 18
                                            }}
                                        >
                                            {currentBout && leftFencer && rightFencer ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        height: "100%"
                                                    }}
                                                    key={`currentBout${currentBout.order}`}
                                                >
                                                    <BoutFencerSection bout={currentBout} fencer={leftFencer} mobile={false} side="left" />
                                                    <BoutLog log={currentBoutLog} mobile={false} />
                                                    <BoutFencerSection
                                                        bout={currentBout}
                                                        fencer={rightFencer}
                                                        mobile={false}
                                                        side="right"
                                                    />
                                                </div>
                                            ) : (
                                                <p>You have not selected any bouts!</p>
                                            )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </>
                    ) : (
                        <div ref={bottomRef}>
                            <TbTTabView ref={tabsRef}>
                                <TbTTab title="Summary">
                                    <SummaryComponent
                                        score1={score1}
                                        score2={score2}
                                        timeouts1={timeouts1}
                                        timeouts2={timeouts2}
                                        squadScores1={squadScores1}
                                        squadScores2={squadScores2}
                                        bouts={bouts}
                                        setWeapon={setWeapon}
                                    />
                                </TbTTab>
                                <TbTTab title="Individual Stats">
                                    <IndividualStats
                                        weapon={weapon}
                                        stats={weaponIndividualStats}
                                        team1Info={team1Info}
                                        team2Info={team2Info}
                                    />
                                </TbTTab>
                                <TbTTab title="Selected Bout">
                                    <Card
                                        sx={{
                                            maxWidth: 500,
                                            width: "90vw",
                                            margin: "auto",
                                            maxHeight: currentBout ? 515 : 315,
                                            overflow: "auto",
                                            borderRadius: 3
                                        }}
                                    >
                                        <CardHeader
                                            title="Selected Bout"
                                            action={
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        left: 12,
                                                        top: 15
                                                    }}
                                                >
                                                    {superadmin && currentBout ? (
                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            href={`/dualMeetScorer/${id}?bout=${currentBout.id}`}
                                                            className="dualMeetInfoEditBoutButton"
                                                        >
                                                            Edit
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            onClick={() => tryShowingPINInput("score")}
                                                            color="secondary"
                                                            variant="contained"
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </div>
                                            }
                                            sx={{
                                                position: "relative",
                                                backgroundColor: theme =>
                                                    theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.secondary.dark,
                                                fontWeight: "bold !important"
                                            }}
                                        />
                                        <CardContent sx={{ padding: "0 !important" }}>
                                            <div
                                                style={{
                                                    margin: "auto",
                                                    flexShrink: 0
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: "rgba(0, 0, 0, 0.95)",
                                                        textAlign: "center",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        color: "white",
                                                        height: (currentBout ? 500 : 300) - 52,
                                                        fontSize: 18
                                                    }}
                                                >
                                                    {currentBout && leftFencer && rightFencer ? (
                                                        <div
                                                            style={{
                                                                display: "flex"
                                                            }}
                                                            key={`currentBout${currentBout.order}`}
                                                        >
                                                            <BoutFencerSection
                                                                bout={currentBout}
                                                                fencer={leftFencer}
                                                                side="left"
                                                                mobile={true}
                                                            />
                                                            <BoutFencerSection
                                                                bout={currentBout}
                                                                fencer={leftFencer}
                                                                side="right"
                                                                mobile={true}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <p>You have not selected any bouts!</p>
                                                    )}
                                                    {currentBout ? <BoutLog log={currentBoutLog} mobile={true} /> : null}
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </TbTTab>
                                <TbTTab title="Bout List">
                                    <Card
                                        sx={{
                                            maxWidth: 500,
                                            width: "90vw",
                                            margin: "auto",
                                            maxHeight: 375,
                                            overflow: "auto",
                                            borderRadius: 3,
                                            position: "relative"
                                        }}
                                    >
                                        <CardHeader
                                            title="Bout List"
                                            sx={{
                                                backgroundColor: theme =>
                                                    theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.secondary.dark,
                                                fontWeight: "bold !important"
                                            }}
                                            action={
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        left: 12,
                                                        top: 13,
                                                        display: "flex"
                                                    }}
                                                >
                                                    <WeaponDropdown />
                                                </div>
                                            }
                                        />
                                        <CardContent sx={{ padding: "0 !important" }}>
                                            <div
                                                style={{
                                                    margin: "auto",
                                                    flexShrink: 0
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: "rgba(0, 0, 0, 0.95)",
                                                        textAlign: "center",
                                                        minHeight: 325
                                                    }}
                                                >
                                                    <BoutsList
                                                        bouts={bouts}
                                                        boutsRaw={boutsRaw}
                                                        runningScores={runningScores}
                                                        setCurrentBoutID={setCurrentBoutID}
                                                        weapon={weapon}
                                                        tabsRef={tabsRef}
                                                    />
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </TbTTab>
                            </TbTTabView>
                        </div>
                    )}
                </>
            ) : (
                <CommonLoading color="#714FCA" />
            )}
            <Dialog open={publishing} onClose={() => setPublishing(false)}>
                <DialogTitle>Publish Meet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Publishing this meet will make this meet publicly visible for everyone to see. Make sure you do this before you
                        start the meet!
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
                    <Button sx={{ marginRight: "10px" }} variant="text" onClick={() => setPublishing(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            setMeetPublished();
                            setPublishing(false);
                        }}
                    >
                        Publish
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={viewingPins} onClose={() => setViewingPins(false)}>
                <DialogTitle>Scoring PIN</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ margin: "10px 0", fontWeight: 400 }}>
                        This PIN allows your team members, coaches, or referees to help score this dual meet.{" "}
                        <strong>Do not give this PIN to anyone whom you do not want editing the meet score!</strong>
                    </DialogContentText>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <DialogContentText style={{ margin: 0, fontWeight: 400, color: "white" }}>
                            Scoring PIN: <strong>{administratorOfTeam === "left" ? dualMeetInfo?.pin1 : dualMeetInfo?.pin2}</strong>
                        </DialogContentText>
                        <IconButton
                            onClick={() => {
                                setClipboardPIN();
                                setViewingPins(false);
                            }}
                        >
                            <Icon path={mdiContentCopy} size={1} horizontal vertical rotate={180} />
                        </IconButton>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setViewingPins(false);
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deletingMeet} onClose={() => setDeletingMeet(false)}>
                <DialogTitle>Delete meet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this meet? <strong>This cannot be undone!</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
                    <Button
                        sx={{ marginRight: "10px" }}
                        variant="text"
                        color="error"
                        onClick={() => {
                            setDeletingMeet(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            deleteMeet();
                            setDeletingMeet(false);
                        }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={endingMeet} onClose={() => setEndingMeet(false)}>
                <DialogTitle>End meet</DialogTitle>
                <DialogContent>
                    {score1 + score2 === 27 ? (
                        <>
                            <DialogContentText>Do you want to end this dual meet?</DialogContentText>
                        </>
                    ) : (
                        <>
                            <DialogContentText>
                                Do you want to forfeit this dual meet? <strong>This will forfeit all of your unfinished bouts!</strong>
                            </DialogContentText>
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
                    {(userInfo?.flags || 0) & UserFlag.MeetManager || score1 + score2 === 27 ? (
                        <>
                            <Button
                                sx={{ marginRight: "10px" }}
                                variant="contained"
                                color="success"
                                onClick={() => {
                                    endMeet("force");
                                    setEndingMeet(false);
                                }}
                            >
                                End meet
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                sx={{ marginRight: "10px" }}
                                variant="contained"
                                color="success"
                                onClick={() => {
                                    endMeet(administratorOfTeam!);
                                    setEndingMeet(false);
                                }}
                            >
                                Forfeit meet
                            </Button>
                        </>
                    )}
                    {(userInfo?.flags || 0) & UserFlag.MeetManager ? (
                        <>
                            <Button
                                variant="contained"
                                color="success"
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                    endMeet("left");
                                    setEndingMeet(false);
                                }}
                            >
                                Forfeit left
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                    endMeet("right");
                                    setEndingMeet(false);
                                }}
                            >
                                Forfeit right
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                            setEndingMeet(false);
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal open={showingPINInput} onClose={() => setShowingPINInput(false)}>
                <Box sx={modalBoxStyleNarrower}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        {enteringSignaturePIN ? "Enter PIN to sign this meet" : "Enter team PIN to score this meet"}
                    </Typography>
                    <TextField
                        placeholder="Enter PIN"
                        label="Enter PIN"
                        style={{ margin: "10px 0", width: 150 }}
                        type="text"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        onInput={e => setEnteredPIN(Number((e.target as HTMLTextAreaElement).value || "0"))}
                    />
                    <br />
                    <Button variant="contained" color="success" onClick={tryPIN}>
                        Confirm
                    </Button>
                    {PINErrorMessage ? (
                        <Alert sx={{ marginTop: "10px" }} variant="filled" severity="error" onClose={() => setPINErrorMessage("")}>
                            {PINErrorMessage}
                        </Alert>
                    ) : null}
                </Box>
            </Modal>
            <Modal open={exportingMeet} onClose={() => setExportingMeet(false)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca" sx={{ marginBottom: "10px" }}>
                        Export meet
                    </Typography>
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ marginTop: "10px" }}
                        disabled={exportingInProgress}
                        onClick={() => exportMeet()}
                    >
                        {exportingInProgress ? "Exporting..." : "Print NJSIAA PDF Scoresheet"}
                    </Button>
                    {dualMeetInfo ? (
                        <Box>
                            <Typography variant="h5" fontFamily="Lexend Deca" sx={{ margin: "10px 0" }}>
                                NJ School Sports Data
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                                {new Date(dualMeetInfo.startedAt).toLocaleString()}
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                                {dualMeetInfo.team1.name} score (Home): {score1}
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                                {dualMeetInfo.team2.name} score (Away): {score2}
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                                {clinchScore ? `Clinch score: ${clinchScore[0]}-${clinchScore[1]}` : `Meet not clinched`}
                            </Typography>
                            <Typography variant="h6" fontFamily="Lexend Deca" sx={{ margin: "10px 0" }}>
                                Round Scores
                            </Typography>
                            <TableContainer component={Paper} sx={{ marginBottom: "15px" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Round Scores</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>Round 1</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>Round 2</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>Round 3</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Home
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>{roundScores[0][0]}</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>{roundScores[1][0]}</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>{roundScores[2][0]}</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>{score1}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Away
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>{roundScores[0][1]}</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>{roundScores[1][1]}</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>{roundScores[2][1]}</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>{score2}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography variant="h6" fontFamily="Lexend Deca" sx={{ margin: "10px 0" }}>
                                Weapon Scores
                            </Typography>
                            <WeaponScores scores={weaponScores} />
                            <Typography variant="h6" fontFamily="Lexend Deca" sx={{ margin: "10px 0" }}>
                                Individual Stats
                            </Typography>
                            <Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <IndividualStatsTable home={true} weapon="Sabre" stats={individualStats.team1.Sabre} />
                                    <Box style={{ width: "40px" }}></Box>
                                    <IndividualStatsTable home={false} weapon="Sabre" stats={individualStats.team2.Sabre} />
                                </Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        margin: "20px 0"
                                    }}
                                >
                                    <IndividualStatsTable home={true} weapon="Foil" stats={individualStats.team1.Foil} />
                                    <Box style={{ width: "40px" }}></Box>
                                    <IndividualStatsTable home={false} weapon="Foil" stats={individualStats.team2.Foil} />
                                </Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <IndividualStatsTable home={true} weapon="Epee" stats={individualStats.team1.Epee} />
                                    <Box style={{ width: "40px" }}></Box>
                                    <IndividualStatsTable home={false} weapon="Epee" stats={individualStats.team2.Epee} />
                                </Box>
                            </Box>
                        </Box>
                    ) : null}
                </Box>
            </Modal>
            <Modal open={editingDate} onClose={() => setEditingDate(false)}>
                <Box sx={modalBoxStyleNarrower}>
                    <Typography variant="h5" fontFamily="Lexend Deca" sx={{ marginBottom: "20px" }}>
                        Edit date
                    </Typography>
                    <DateTimePicker
                        renderInput={props => <TextField {...props} />}
                        desktopModeMediaQuery="@media only screen and (min-width: 600px)"
                        label="Meet Start Date"
                        value={newDate}
                        onChange={setNewDate}
                    />
                    <Button variant="contained" onClick={submitNewDate} sx={{ margin: "15px 10px 0 0" }}>
                        Confirm
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ marginTop: "15px" }}
                        onClick={() => {
                            setNewDate(dualMeetInfo?.startedAt ? new Date(dualMeetInfo.startedAt) : new Date());
                            setEditingDate(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Modal>
            <Dialog open={configuringRefs} onClose={() => setConfiguringRefs(false)}>
                <DialogTitle>{canManageMeet ? "Edit" : "View"} Referees</DialogTitle>
                <DialogContent>
                    <Box>
                        <FormControlLabel
                            value="start"
                            control={<Switch onChange={() => setSingleReferee(!singleReferee)} checked={singleReferee} />}
                            label="Use single referee?"
                        />
                    </Box>
                    {singleReferee ? (
                        <Box className="refereeLine">
                            <Typography sx={{ marginRight: "10px" }}>Meet Referee:</Typography>
                            {configuringAll ? (
                                <TextField
                                    sx={{ marginTop: "-10px" }}
                                    variant="standard"
                                    size="small"
                                    label="Referee Name"
                                    value={refNameAll}
                                    onChange={e => setRefNameAll(e.target.value)}
                                />
                            ) : (
                                <Typography>{refNameAll || "Unset"}</Typography>
                            )}
                            {Boolean(canManageMeet) && (
                                <Button
                                    sx={{ margin: "0 0 0 5px" }}
                                    onClick={() => (configuringAll ? saveReferee("All") : setConfiguringAll(true))}
                                >
                                    {configuringAll ? "Save" : "Edit"}
                                </Button>
                            )}
                        </Box>
                    ) : (
                        <>
                            <Box className="refereeLine">
                                <Typography sx={{ marginRight: "10px" }}>Sabre:</Typography>
                                {configuringSabre ? (
                                    <TextField
                                        sx={{ marginTop: "-10px" }}
                                        variant="standard"
                                        size="small"
                                        label="Referee Name"
                                        value={refNameSabre}
                                        onChange={e => setRefNameSabre(e.target.value)}
                                    />
                                ) : (
                                    <Typography>{refNameSabre || "Unset"}</Typography>
                                )}
                                {Boolean(canManageMeet) && (
                                    <Button
                                        sx={{ margin: "0 0 0 5px" }}
                                        onClick={() => (configuringSabre ? saveReferee("Sabre") : setConfiguringSabre(true))}
                                    >
                                        {configuringSabre ? "Save" : "Edit"}
                                    </Button>
                                )}
                            </Box>
                            <Box className="refereeLine" sx={{ mv: "5px" }}>
                                <Typography sx={{ marginRight: "10px" }}>Foil:</Typography>
                                {configuringFoil ? (
                                    <TextField
                                        sx={{ marginTop: "-10px" }}
                                        variant="standard"
                                        size="small"
                                        label="Referee Name"
                                        value={refNameFoil}
                                        onChange={e => setRefNameFoil(e.target.value)}
                                    />
                                ) : (
                                    <Typography>{refNameFoil || "Unset"}</Typography>
                                )}
                                {Boolean(canManageMeet) && (
                                    <Button
                                        sx={{ margin: "0 0 0 5px" }}
                                        onClick={() => (configuringFoil ? saveReferee("Foil") : setConfiguringFoil(true))}
                                    >
                                        {configuringFoil ? "Save" : "Edit"}
                                    </Button>
                                )}
                            </Box>
                            <Box className="refereeLine">
                                <Typography sx={{ marginRight: "10px" }}>Epee:</Typography>
                                {configuringEpee ? (
                                    <TextField
                                        sx={{ marginTop: "-10px" }}
                                        variant="standard"
                                        size="small"
                                        label="Referee Name"
                                        value={refNameEpee}
                                        onChange={e => setRefNameEpee(e.target.value)}
                                    />
                                ) : (
                                    <Typography>{refNameEpee || "Unset"}</Typography>
                                )}
                                {Boolean(canManageMeet) && (
                                    <Button
                                        sx={{ margin: "0 0 0 5px" }}
                                        onClick={() => (configuringEpee ? saveReferee("Epee") : setConfiguringEpee(true))}
                                    >
                                        {configuringEpee ? "Save" : "Edit"}
                                    </Button>
                                )}
                            </Box>
                        </>
                    )}
                </DialogContent>
            </Dialog>
            <Dialog open={choosingSignature} onClose={() => setChoosingSignature(false)}>
                <DialogTitle>Sign Meet</DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    <Box sx={{ paddingLeft: "10px" }}>
                        <FormControlLabel
                            value="start"
                            control={<Switch onChange={() => setSingleReferee(!singleReferee)} checked={singleReferee} />}
                            label="Use single referee?"
                        />
                    </Box>
                    <List>
                        {singleReferee ? (
                            <ListItemButton onClick={() => startSigning("Meet Referee")}>
                                <ListItemText>Meet Referee - {meetRefereeSigned ? "Signed" : "Unsigned"}</ListItemText>
                            </ListItemButton>
                        ) : (
                            <>
                                <ListItemButton onClick={() => startSigning("Sabre")}>
                                    <ListItemText>
                                        Sabre Referee - {dualMeetInfo?.signatures?.sabreRef ? "Signed" : "Unsigned"}
                                    </ListItemText>
                                </ListItemButton>
                                <ListItemButton onClick={() => startSigning("Foil")}>
                                    <ListItemText>Foil Referee - {dualMeetInfo?.signatures?.foilRef ? "Signed" : "Unsigned"}</ListItemText>
                                </ListItemButton>
                                <ListItemButton onClick={() => startSigning("Epee")}>
                                    <ListItemText>Epee Referee - {dualMeetInfo?.signatures?.epeeRef ? "Signed" : "Unsigned"}</ListItemText>
                                </ListItemButton>
                            </>
                        )}
                        <ListItemButton onClick={() => startSigning("Home Team")}>
                            <ListItemText>
                                {dualMeetInfo?.team1?.abbreviation || "Home Team"} -{" "}
                                {dualMeetInfo?.signatures?.team1 ? "Signed" : "Unsigned"}
                            </ListItemText>
                        </ListItemButton>
                        <ListItemButton onClick={() => startSigning("Away Team")}>
                            <ListItemText>
                                {dualMeetInfo?.team2?.abbreviation || "Away Team"} -{" "}
                                {dualMeetInfo?.signatures?.team2 ? "Signed" : "Unsigned"}
                            </ListItemText>
                        </ListItemButton>
                    </List>
                </DialogContent>
            </Dialog>
            <Dialog open={signing} onClose={() => setSigning(false)}>
                <DialogTitle>{signatureTitle}</DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    {existingSignature ? (
                        <Typography>
                            This meet has been signed for {signingWeapon} by {existingSignature}. If this is in error, please contact the
                            TbT admins.
                        </Typography>
                    ) : (
                        <>
                            <Typography>
                                Enter your digital signature to sign the meet. Make sure all entered fencers and scores are correct before
                                signing!
                            </Typography>
                            <TextField
                                sx={{ marginTop: "10px" }}
                                variant="outlined"
                                label="Digital Signature"
                                value={signature}
                                onChange={e => setSignature(e.target.value)}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {existingSignature ? (
                        <Button onClick={() => setSigning(false)}>Okay</Button>
                    ) : (
                        <Button disabled={!signature.trim()} onClick={sign}>
                            Sign
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={editingAttendance} onClose={() => setEditingAttendance(false)}>
                <DialogTitle>Edit attendance</DialogTitle>
                <DialogContent>
                    <List>
                        <ListItemButton onClick={() => setCurrentSchoolAttendanceEdit("Home Team")}>
                            <ListItemText>Edit {dualMeetInfo?.team1?.abbreviation || "Home Team"} Attendance</ListItemText>
                        </ListItemButton>
                        <ListItemButton onClick={() => setCurrentSchoolAttendanceEdit("Away Team")}>
                            <ListItemText>Edit {dualMeetInfo?.team2?.abbreviation || "Away Team"} Attendance</ListItemText>
                        </ListItemButton>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "1rem"
                            }}
                        >
                            {dualMeetInfo?.team1?.abbreviation || "Home Team"}
                            <span
                                style={{
                                    marginLeft: "0.5rem",
                                    marginRight: "0.5rem"
                                }}
                            >
                                -
                            </span>
                            {dualMeetInfo?.team1?.attendance ? (
                                <Icon path={mdiCheck} size={1} color="#28A745" />
                            ) : (
                                <Icon path={mdiClose} size={1} color="#CC3333" />
                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "1rem"
                            }}
                        >
                            {dualMeetInfo?.team2?.abbreviation || "Home Team"}
                            <span
                                style={{
                                    marginLeft: "0.5rem",
                                    marginRight: "0.5rem"
                                }}
                            >
                                -
                            </span>
                            {dualMeetInfo?.team2?.attendance ? (
                                <Icon path={mdiCheck} size={1} color="#28A745" />
                            ) : (
                                <Icon path={mdiClose} size={1} color="#CC3333" />
                            )}
                        </div>
                    </List>
                </DialogContent>
            </Dialog>
            <Dialog open={Boolean(currentSchoolAttendanceEdit)} onClose={hideTeamAttendance}>
                <DialogTitle>
                    Edit{" "}
                    {currentSchoolAttendanceEdit == "Home Team"
                        ? dualMeetInfo?.team1?.abbreviation || "Home Team"
                        : dualMeetInfo?.team2?.abbreviation || "Away Team"}{" "}
                    Attendance
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <FormControl component="fieldset" sx={{ color: "inherit" }}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox name="Select all" onChange={toggleSelectAllFencers} checked={areAllFencersSelected} />
                                    }
                                    label={
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                letterSpacing: "0.5px"
                                            }}
                                        >
                                            Select all
                                        </span>
                                    }
                                    disabled={Boolean(existingAttendanceSignature)}
                                />

                                {currentTeam && (
                                    <>
                                        <FencerAttendanceList weapon="Epee" allFencers={currentTeam.fencers.Epee} />
                                        <FencerAttendanceList weapon="Foil" allFencers={currentTeam.fencers.Foil} />
                                        <FencerAttendanceList weapon="Sabre" allFencers={currentTeam.fencers.Sabre} />
                                    </>
                                )}
                            </FormGroup>
                            {existingAttendanceSignature ? (
                                <Typography>
                                    This meet's attendance has been signed for by {existingAttendanceSignature}. If this is an error, please
                                    contact the TbT admins.
                                </Typography>
                            ) : (
                                <>
                                    <FormHelperText>
                                        To submit meet attendance, the team's coach must provide their digital signature below.
                                    </FormHelperText>
                                    <TextField
                                        sx={{ marginTop: "10px" }}
                                        variant="outlined"
                                        label="Digital Signature"
                                        value={attendanceSignature}
                                        onChange={e => setAttendanceSignature(e.target.value)}
                                    />
                                </>
                            )}
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {existingAttendanceSignature ? (
                        <Button onClick={hideTeamAttendance}>Okay</Button>
                    ) : (
                        <Button disabled={!attendanceSignature.trim() || selectedFencers.length == 0} onClick={submitAttendance}>
                            Submit
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={repairingScore} onClose={() => setRepairingScore(false)}>
                <DialogTitle>Repair meet score</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        In the event that the displayed meet score does not match the score calculated by counting who won each bout, you
                        can try using this tool to repair the displayed meet score. Note that this will not repair any mistakes in
                        scorekeeping.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
                    <Button
                        sx={{ marginRight: "10px" }}
                        variant="text"
                        onClick={() => {
                            setRepairingScore(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            repairScore();
                            setRepairingScore(false);
                        }}
                    >
                        Repair
                    </Button>
                </DialogActions>
            </Dialog>

            <MedicalDialog
                medicalInfoOpen={medicalInfoOpen}
                setMedicalInfoOpen={setMedicalInfoOpen}
                medicalInfoFencer={medicalInfoFencer}
            />
            <SnackbarError error={snackbarError} close={() => setSnackbarError("")} />
        </TbTPage>
    );
}
