import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IDualMeetBout, IDualMeetBoutFencerInfo, IDualMeet_DB, IExistingFencer, ITeam } from "../../../types";
import useDatabase from "../../../hooks/database";
import Box from "@mui/material/Box";
import TbTPage from "../../../components/TbTPage/TbTPage";
import NotFound from "../NotFound/NotFound";
import { CommonLoading } from "../../../components/Loading/Loading";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import "./FencerInfo.css";
import { boutWinner } from "../../../utils/helpers";
import { MeetSection, FencerInfoBoxHighSchool } from "../../../components/FencerInfo/FencerInfo";
import { DBResult, isSuccess, getTeam } from "../../../utils/database";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Menu } from "@mui/material";
import { Dropdown, Table } from "react-bootstrap";
import Switch from "@mui/material/Switch";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import { useHistory } from "react-router-dom";
const FencerInfo = () => {
    const history = useHistory();
    const { id } = useParams();
    const DB = useDatabase();

    const season = useSelector((s: ReduxState) => s.season);

    const [loaded, setLoaded] = useState(false);

    const [notFound, setNotFound] = useState(false);

    const [criticalError, setCriticalError] = useState("");
    const [snackbarError, setSnackbarError] = useState("");

    const [fencerInfo, setFencerInfo] = useState<IExistingFencer | null>(null);
    const [teams, setTeams] = useState<Record<string, ITeam>>({});
    const [fencerSpecific, setSpecific] = useState("");
    const [meets, setMeets] = useState<IDualMeet_DB[]>([]);
    const [meetBoutRecPublished, setMeetBoutRecPublished] = useState<IDualMeet_DB[]>([]);

    const [meetBoutRec, setMeetBoutRec] = useState<Record<string, IDualMeetBout[]>>({});

    const [chosenMeet, setChosenMeet] = useState(-1);

    const [meetBoutDates, setMeetBoutDates] = useState<Record<string, number>>({});

    const handleFencerInfo = (result: DBResult<IExistingFencer>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        setFencerInfo(result.data);
    };

    useEffect(() => {
        DB.getFencer(id)
            .then(handleFencerInfo)
            .catch((e: Error) => {
                setCriticalError(e.message);
            });
    }, [id]);

    useEffect(() => {
        if (fencerInfo?.teams) {
            Promise.all(fencerInfo.teams.map(l => DB.getTeam(l))).then(t => {
                const res: Record<string, ITeam> = {};
                for (const team of t) {
                    if (team.status === "fail") continue;
                    res[team.data.id] = team.data;
                }
                setTeams(res);
            });
        }
    }, [fencerInfo?.teams]);

    // data collection for the list provided to the "params" option.
    const [opponentIds, setOpponentIds] = useState<string[]>([]);
    const [opponentInfo, setOpponentInfo] = useState<IDualMeetBoutFencerInfo[]>([]);
    const [opponentNames, setOpponentNames] = useState<string[]>([]);

    const [chosenFencer, setChosenfencer] = useState(-1);

    const [teamIds, setTeamIds] = useState<string[]>([]);
    const [teamInfo, setTeamInfo] = useState<IDualMeetBoutFencerInfo[]>([]);
    const [teamNames, setTeamNames] = useState<string[]>([]);
    const [yourTeams, setYourTeams] = useState<string[]>([]);

    const [chosenTeam, setChosenTeam] = useState(-1);

    const [chosenOrder, setChosenOrder] = useState(true);
    // if false, a - b;
    // if true, b - a;

    // This useEffect hook parses through all of the meets and all of the fencers.
    // It also handles both of the autocomplete forms. (Although the way they are done is... questionable, to say the least.)

    useEffect(() => {
        (async () => {
            const meetIDs: string[] = [];
            for (const teamID in teams) {
                const team = teams[teamID];
                if (team.dualMeets[season]) meetIDs.push(...team.dualMeets[season]);
            }

            const m = await Promise.all(meetIDs.map(l => DB.getDualMeetRaw(l)));

            let dbMeets;
            if (chosenOrder) {
                dbMeets = m
                    .filter(isSuccess)
                    .map(l => l.data)
                    .sort((a, b) => b.startedAt - a.startedAt);
            } else {
                dbMeets = m
                    .filter(isSuccess)
                    .map(l => l.data)
                    .sort((a, b) => a.startedAt - b.startedAt);
            }
            setMeets(dbMeets);

            const current = yourTeams;
            for (const team in teams) {
                current.push(teams[team].name);
            }
            setYourTeams(current);

            const a = teamNames;
            for (const meet of dbMeets) {
                if (yourTeams.indexOf(meet.team1Name) < 0) {
                    // this means that the team1 was not your team!

                    if (a.indexOf(meet.team1Name) < 0) {
                        a.push(meet.team1Name);
                    }
                } else if (yourTeams.indexOf(meet.team2Name) < 0) {
                    // this means that the team2 was not your team!

                    if (a.indexOf(meet.team2Name) < 0) a.push(meet.team2Name);
                }
            }

            setTeamNames(a);

            const boutIDs: string[] = [];

            if (chosenTeam >= 0) {
                for (const meet of dbMeets) {
                    if (meet.name.indexOf(teamNames[chosenTeam]) >= 0) boutIDs.push(...meet.bouts.map(l => l.id));
                }
            } else {
                for (const meet of dbMeets) {
                    boutIDs.push(...meet.bouts.map(l => l.id));
                }
            }

            const b = await Promise.allResolved(boutIDs.map(l => DB.getBout(l)));
            const dbBouts = b.filter(isSuccess).map(l => l.data);

            const boutsObj: Record<string, IDualMeetBout[]> = {};

            if (chosenFencer > -1) {
                for (const bout of dbBouts) {
                    if (
                        bout.fencer1.fencerInfo.id === opponentIds[chosenFencer] ||
                        bout.fencer2.fencerInfo.id == opponentIds[chosenFencer]
                    ) {
                        if (bout.fencer1.fencerInfo.id === id) {
                            if (boutsObj[bout.dualMeetId]) {
                                boutsObj[bout.dualMeetId].push(bout);
                            } else {
                                boutsObj[bout.dualMeetId] = [bout];
                            }
                            if (bout.fencer2.fencerInfo.id) {
                                if (opponentIds.indexOf(bout.fencer2.fencerInfo.id) === -1) {
                                    const x = opponentIds;
                                    x.push(bout.fencer2.fencerInfo.id);
                                    setOpponentIds(x);
                                    const y = opponentInfo;
                                    y.push(bout.fencer2.fencerInfo);
                                    setOpponentInfo(y);
                                    const z = opponentNames;
                                    z.push(bout.fencer2.fencerInfo.firstName + " " + bout.fencer2.fencerInfo.lastName);
                                    setOpponentNames(z);
                                }
                            }
                        }

                        if (bout.fencer2.fencerInfo.id === id) {
                            if (boutsObj[bout.dualMeetId]) {
                                boutsObj[bout.dualMeetId].push(bout);
                            } else {
                                boutsObj[bout.dualMeetId] = [bout];
                            }

                            if (bout.fencer1.fencerInfo.id) {
                                if (opponentIds.indexOf(bout.fencer1.fencerInfo.id) === -1) {
                                    // idk how to typescript? had this been python i would have just done a calm luh "not"
                                    const x = opponentIds;
                                    x.push(bout.fencer1.fencerInfo.id);
                                    setOpponentIds(x);
                                    const y = opponentInfo;
                                    y.push(bout.fencer1.fencerInfo);
                                    setOpponentInfo(y);
                                    const z = opponentNames;
                                    z.push(bout.fencer1.fencerInfo.firstName + " " + bout.fencer1.fencerInfo.lastName);
                                    setOpponentNames(z);
                                }
                            }
                        }
                    }
                }
            } else {
                for (const bout of dbBouts) {
                    if (bout.fencer1.fencerInfo.id === id) {
                        if (boutsObj[bout.dualMeetId]) {
                            boutsObj[bout.dualMeetId].push(bout);
                        } else {
                            boutsObj[bout.dualMeetId] = [bout];
                        }
                        if (bout.fencer2.fencerInfo.id) {
                            if (opponentIds.indexOf(bout.fencer2.fencerInfo.id) === -1) {
                                const x = opponentIds;
                                x.push(bout.fencer2.fencerInfo.id);
                                setOpponentIds(x);
                                const y = opponentInfo;
                                y.push(bout.fencer2.fencerInfo);
                                setOpponentInfo(y);
                                const z = opponentNames;
                                z.push(bout.fencer2.fencerInfo.firstName + " " + bout.fencer2.fencerInfo.lastName);
                                setOpponentNames(z);
                            }
                        }
                    }

                    if (bout.fencer2.fencerInfo.id === id) {
                        if (boutsObj[bout.dualMeetId]) {
                            boutsObj[bout.dualMeetId].push(bout);
                        } else {
                            boutsObj[bout.dualMeetId] = [bout];
                        }

                        if (bout.fencer1.fencerInfo.id) {
                            if (opponentIds.indexOf(bout.fencer1.fencerInfo.id) === -1) {
                                // idk how to typescript? had this been python i would have just done a calm luh "not"
                                const x = opponentIds;
                                x.push(bout.fencer1.fencerInfo.id);
                                setOpponentIds(x);
                                const y = opponentInfo;
                                y.push(bout.fencer1.fencerInfo);
                                setOpponentInfo(y);
                                const z = opponentNames;
                                z.push(bout.fencer1.fencerInfo.firstName + " " + bout.fencer1.fencerInfo.lastName);
                                setOpponentNames(z);
                            }
                        }
                    }
                }
            }

            const ids = opponentIds;
            const info = opponentInfo;
            const names = opponentNames;
            let teamns = teamNames;

            const x = ids.filter(id => id.indexOf("writeIn") != 0);

            const y = info.filter(inf => inf.firstName != "Unknown");

            for (const team of teamns) {
                let exists = false;
                for (const athlete of info) {
                    if (athlete.teamName === team) {
                        exists = true;
                        break;
                    }
                }

                if (!exists) {
                    console.log("We will be cutting this team: " + team);
                    teamns = teamns.filter(t => t != team);
                }
            }

            setTeamNames(teamns);
            let z = names.filter(name => name != "Unknown fencer");
            if (x[x.length - 1] != "-1") {
                x.push("-1");
            }

            if (chosenTeam >= 0) {
                for (const i in z) {
                    if (info[i].teamName != teamNames[chosenTeam]) z = z.filter(name => name != info[i].firstName + " " + info[i].lastName);
                }
            }

            // TODO: Right now, I just added in the IfencerInfo listing to make it easier for osmeone in the future to add some more functionality to this if they wanted to.
            // so, as of right now, it really does not do much.
            setOpponentIds(x);
            setOpponentInfo(y);
            setOpponentNames(z);
            setMeetBoutRec(boutsObj);
            setMeetBoutRecPublished(meetBoutRec);

            setMeetBoutRecPublished(meetBoutRec);
            setLoaded(true);
        })();
    }, [teams, chosenFencer, chosenOrder, chosenTeam]);
    const handlePlayerChange = event => {
        setChosenfencer(event.target.value);
    };

    const handlePlayerNameChange = (name: string) => {
        if (name == "All Fencers") {
            setChosenfencer(-1);
            setChosenTeam(-1);
        } else if (name == null || name == "") {
            setChosenfencer(-1);
            setChosenTeam(-1);
        } else {
            setChosenfencer(opponentNames.indexOf(name) + 1);
            setChosenTeam(-1);
        }
    };

    const handleTeamNameChange = (name: string) => {
        if (name == "All Teams") {
            setChosenTeam(-1);
        } else if (name == null || name == "") {
            setChosenTeam(-1);
        } else {
            setChosenTeam(teamNames.indexOf(name));
            setChosenfencer(-1);
        }
    };

    const handleMeetOrderChange = event => {
        setChosenOrder(event.target.checked);
    };

    // iterates through all of the bouts and calculates the amount of wins and amount of total bouts.

    let boutWins = 0;
    let totalBouts = 0;

    const allWins: Record<string, IDualMeetBout[]> = {};
    // TODO: Add later.
    // const personLeastWins: Record<string, IDualMeetBout[]> = {};

    for (const meet in meetBoutRec) {
        for (const bout of meetBoutRec[meet]) {
            const winner = boutWinner(bout);

            if (winner) {
                totalBouts += 1;

                // pushing all wins and all losses (not yet implemented) into a record (set, hashmap, for normal people coming from any other real language since for some reason ts calls it a record?!)
                // iterating through the record later to figure out who has the most wins and what not. O(3n) technically, could probably be solved with O(something less than that),
                // but to be honest, it does not make that much of a difference and I think that it will be helpful if we want to add features later maybe?!

                if (bout.fencer1.fencerInfo.id === id && winner === 1) {
                    boutWins += 1;
                    if (bout.fencer2.fencerInfo.id) {
                        if (allWins[bout.fencer2.fencerInfo.id]) {
                            allWins[bout.fencer2.fencerInfo.id].push(bout);
                        } else {
                            allWins[bout.fencer2.fencerInfo.id] = [bout];
                        }
                    }
                }

                if (bout.fencer2.fencerInfo.id === id && winner === 2) {
                    boutWins += 1;
                    if (bout.fencer1.fencerInfo.id) {
                        if (allWins[bout.fencer1.fencerInfo.id]) {
                            allWins[bout.fencer1.fencerInfo.id].push(bout);
                        } else {
                            allWins[bout.fencer1.fencerInfo.id] = [bout];
                        }
                    }
                }
            }
        }
    }

    if (notFound) {
        return <NotFound message="The requested fencer could not be found." />;
    }

    if (!loaded) {
        return <TbTPage>Loading...</TbTPage>;
    }

    if (!fencerInfo) {
        return (
            <TbTPage>
                <br></br>
                <h1>This fencer could not be found.</h1>
                <Link href="/">Return to homepage.</Link>
            </TbTPage>
        );
    }

    if (meets.length == 0) {
        return (
            <TbTPage>
                <br></br>
                <h1>This season is empty. </h1>

                <Button variant="contained" onClick={history.goBack}>
                    Return to previous page
                </Button>
            </TbTPage>
        );
    }

    return (
        <TbTPage className="eventsPage">
            <div style={{ height: 50, margin: "auto" }}></div>

            <h1 style={{ fontWeight: "500", fontFamily: "Lexend Deca" }}>
                {fencerInfo.firstName} {fencerInfo.lastName}
            </h1>
            <br></br>

            <Accordion style={{ width: "60%", margin: "auto" }} defaultExpanded={false}>
                <AccordionSummary expandIcon={<Icon path={mdiChevronDown} size="20px" />} aria-controls="panel1-content" id="panel1-header">
                    <Typography>Statistics</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex", flexDirection: "row", width: "100%", margin: "auto", flexWrap: "wrap" }}>
                    <div
                        style={{
                            minWidth: 200,
                            maxWidth: 200,
                            margin: "auto",
                            marginBottom: "10px",
                            borderRadius: "30px",
                            alignItems: "center",
                            justifyContent: "center",
                            marginY: "auto",
                            borderWidth: "0px",
                            borderStyle: "solid",
                            borderColor: "#FF00FF"
                        }}
                    >
                        <Card sx={{ width: "200px", margin: "auto", borderRadius: "30px" }}>
                            <CardContent sx={{ marginBottom: "0px" }}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Wins:
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {boutWins}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div
                        style={{
                            minWidth: 200,
                            maxWidth: 200,
                            margin: "auto",
                            marginBottom: "10px",
                            borderRadius: "30px",
                            alignItems: "center",
                            justifyContent: "center",
                            marginY: "auto",
                            borderWidth: "0px",
                            borderStyle: "solid",
                            borderColor: "#FF00FF"
                        }}
                    >
                        <Card sx={{ width: "200px", margin: "auto", borderRadius: "30px" }}>
                            <CardContent sx={{ marginBottom: "0px" }}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Total Bouts:
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {totalBouts}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>

                    <div
                        style={{
                            minWidth: 200,
                            maxWidth: 200,
                            margin: "auto",
                            marginBottom: "10px",
                            borderRadius: "30px",
                            alignItems: "center",
                            justifyContent: "center",
                            marginY: "auto",
                            borderWidth: "0px",
                            borderStyle: "solid",
                            borderColor: "#FF00FF"
                        }}
                    >
                        <Card sx={{ width: "200px", margin: "auto", borderRadius: "30px" }}>
                            <CardContent sx={{ marginBottom: "0px" }}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Win Percentage:
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {Math.round((boutWins * 100) / totalBouts)}%
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </AccordionDetails>
            </Accordion>

            <br></br>
            <Accordion style={{ width: "60%", margin: "auto" }} defaultExpanded={false}>
                <AccordionSummary expandIcon={<Icon path={mdiChevronDown} size="20px" />} aria-controls="panel2-content" id="panel2-header">
                    <Typography>Filters</Typography>
                </AccordionSummary>

                <AccordionDetails style={{ display: "flex", flexDirection: "row", width: "100%", margin: "auto", flexWrap: "wrap" }}>
                    <div
                        style={{
                            width: "220px",
                            margin: "auto",
                            marginBottom: "10px",
                            borderRadius: "30px",
                            alignItems: "center",
                            justifyContent: "center",
                            marginY: "auto",
                            borderWidth: "0px",
                            borderStyle: "solid",
                            borderColor: "#FF00FF"
                        }}
                    >
                        <Card sx={{ width: "100%", margin: "auto", borderRadius: "30px" }}>
                            <CardContent sx={{ marginBottom: "0px" }}>
                                <Switch
                                    checked={chosenOrder}
                                    onChange={handleMeetOrderChange}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {chosenOrder ? "Newest Meets First" : "Oldest Meets First"}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>

                    <div
                        style={{
                            width: "220px",
                            margin: "auto",
                            marginBottom: "10px",
                            borderRadius: "30px",
                            alignItems: "center",
                            justifyContent: "center",
                            marginY: "auto",
                            borderWidth: "0px",
                            borderStyle: "solid",
                            borderColor: "#FF00FF"
                        }}
                    >
                        <Card sx={{ width: "100%", margin: "auto", borderRadius: "30px" }}>
                            <CardContent sx={{ marginBottom: "0px", margin: "auto" }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={[...teamNames, "All teams"]}
                                    sx={{ width: "90%", margin: "auto", borderWidth: "0px" }}
                                    renderInput={params => <TextField {...params} label="Choose School" />}
                                    onChange={(event: any, newValue: string | null) => {
                                        handleTeamNameChange(newValue);
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </div>
                    <div
                        style={{
                            width: "220px",
                            margin: "auto",
                            marginBottom: "10px",
                            borderRadius: "30px",
                            alignItems: "center",
                            justifyContent: "center",
                            marginY: "auto",
                            borderWidth: "0px",
                            borderStyle: "solid",
                            borderColor: "#FF00FF"
                        }}
                    >
                        <Card sx={{ width: "100%", margin: "auto", borderRadius: "30px" }}>
                            <CardContent sx={{ marginBottom: "0px", margin: "auto" }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={[...opponentNames, "All Fencers"]}
                                    sx={{ width: "90%", margin: "auto", borderWidth: "0px" }}
                                    renderInput={params => <TextField {...params} label="Choose Fencer" />}
                                    onChange={(event: any, newValue: string | null) => {
                                        handlePlayerNameChange(newValue);
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </div>
                </AccordionDetails>
            </Accordion>

            <h3 style={{ marginTop: "20px" }}>
                {Object.values(teams)
                    .map(l => l.name)
                    .join(", ")}
            </h3>
            <FencerInfoBoxHighSchool key={0} className="fencerInfoBox" meets={meets} meetBoutRec={meetBoutRec} id={id} />

            <div style={{ height: 30 }}></div>
        </TbTPage>
    );
};

export default FencerInfo;
