import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { mdiCog, mdiDelete, mdiLink, mdiMerge, mdiPencil, mdiUpload } from "@mdi/js";
import * as Papa from "papaparse";
import Icon from "@mdi/react";

import { CommonLoading } from "../../../components/Loading/Loading";
import SeasonSwitcher from "../../../components/SeasonTeamSwitcher/SeasonSwitcher";
import PermissionDenied from "../../../components/PermissionDenied/PermissionDenied";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { ITeamInvite, Weapon } from "../../../types";

import "./RosterEditor.css";
import { ITeam, IExistingFencer, Strip, IUser, ID, RosterCSV, UserFlag, ActionQueue } from "../../../types";
import { ReduxState } from "../../../utils/store";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    Modal,
    MenuItem,
    Select,
    Box,
    FormControl,
    TextField,
    Typography,
    InputLabel,
    ListItemText,
    ListItemButton,
    Paper,
    Stack,
    Autocomplete,
    Alert,
    AlertTitle,
    Checkbox,
    FormGroup,
    FormControlLabel,
    createFilterOptions,
    Tooltip
} from "@mui/material";
import { modalBoxStyle } from "../../..";
import saveAs from "file-saver";
import useDatabase from "../../../hooks/database";
import InviteCode from "../../../components/InviteCode";
import { DBResult, isSuccess } from "../../../utils/database";
import TeamSwitcher from "../../../components/SeasonTeamSwitcher/TeamSwitcher";
import RosterAutoUpdateDialog from "../../../components/RosterAutoUpdateDialog";

interface FencersSectionProps {
    teamMembers: Map<IExistingFencer, { home: number; away: number }>;
    discipline: Weapon;
    users: Record<string, IUser>;
    onEdit: (l: IExistingFencer) => void;
    onMerge: (l: IExistingFencer) => void;
    onDelete: (l: IExistingFencer, weapon: Weapon) => void;
    onLink: (l: IExistingFencer) => void;
    onConfigure: (l: IExistingFencer) => void;
    updateStrip: (l: IExistingFencer, home: boolean, weapon: Weapon, strip: number) => void;
}

function FencersSection(props: FencersSectionProps) {
    const { teamMembers, discipline, users, onEdit, onMerge, onDelete, onLink, onConfigure, updateStrip } = props;

    return (
        <Card
            sx={{
                width: "650px",
                maxWidth: "90vw",
                margin: "0 auto 20px auto"
            }}
        >
            <CardHeader
                title={discipline}
                sx={{
                    backgroundColor: theme => theme.palette.secondary.dark
                }}
            />
            <CardContent style={{ padding: "0px 10px" }}>
                <List>
                    {Array.from(teamMembers.entries())
                        .sort((a, b) => (a[0].gradYear || 0) - (b[0].gradYear || 0))
                        .map(([l, role]) => {
                            const linkedUser = Object.values(users).find(j => j.linkedFencerIds.includes(l.id));

                            return (
                                <ListItem key={`itemFencer${l.id}${discipline}`}>
                                    <Select
                                        variant="standard"
                                        size="small"
                                        label="Strip"
                                        placeholder="Strip"
                                        value={role.home}
                                        sx={{ width: 60, marginRight: "15px" }}
                                        displayEmpty
                                    >
                                        <MenuItem
                                            value={3}
                                            onClick={() => updateStrip(l, true, discipline, 3)}
                                            key={`updateStripCasdf${l.id}`}
                                        >
                                            3
                                        </MenuItem>
                                        <MenuItem
                                            value={1}
                                            onClick={() => updateStrip(l, true, discipline, 1)}
                                            key={`updateStripAasdf${l.id}`}
                                        >
                                            1
                                        </MenuItem>
                                        <MenuItem
                                            value={2}
                                            onClick={() => updateStrip(l, true, discipline, 2)}
                                            key={`updateStripBasdf${l.id}`}
                                        >
                                            2
                                        </MenuItem>
                                        <MenuItem
                                            value={0}
                                            onClick={() => updateStrip(l, true, discipline, 0)}
                                            key={`updateStripSubasdf${l.id}`}
                                        >
                                            Sub
                                        </MenuItem>
                                    </Select>
                                    <Select
                                        variant="standard"
                                        size="small"
                                        label="Strip"
                                        placeholder="Strip"
                                        value={role.away}
                                        sx={{ width: 60, marginRight: "15px" }}
                                        displayEmpty
                                    >
                                        <MenuItem
                                            value={6}
                                            onClick={() => updateStrip(l, false, discipline, 6)}
                                            key={`updateStripAasdf${l.id}`}
                                        >
                                            6
                                        </MenuItem>
                                        <MenuItem
                                            value={5}
                                            onClick={() => updateStrip(l, false, discipline, 5)}
                                            key={`updateStripBasdf${l.id}`}
                                        >
                                            5
                                        </MenuItem>
                                        <MenuItem
                                            value={4}
                                            onClick={() => updateStrip(l, false, discipline, 4)}
                                            key={`updateStripCasdf${l.id}`}
                                        >
                                            4
                                        </MenuItem>
                                        <MenuItem
                                            value={0}
                                            onClick={() => updateStrip(l, false, discipline, 0)}
                                            key={`updateStripSubasdf${l.id}`}
                                        >
                                            Sub
                                        </MenuItem>
                                    </Select>
                                    <div>
                                        {l.firstName} {l.lastName} {l.gradYear ? `- ${l.gradYear}` : ""}
                                    </div>
                                    {linkedUser ? (
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                marginLeft: "auto"
                                            }}
                                        >
                                            <Tooltip title="Configure">
                                                <IconButton onClick={() => onConfigure(l)}>
                                                    <Icon path={mdiCog} size={1} horizontal vertical rotate={180} color="#EEE" />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemIcon>
                                    ) : (
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                marginLeft: "auto"
                                            }}
                                        >
                                            <Tooltip title="Link">
                                                <IconButton onClick={() => onLink(l)}>
                                                    <Icon path={mdiLink} size={1} horizontal vertical rotate={180} color="#EEE" />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemIcon>
                                    )}
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <Tooltip title="Merge">
                                            <IconButton onClick={() => onMerge(l)}>
                                                <Icon path={mdiMerge} size={1} horizontal vertical rotate={180} color="#EEE" />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <Tooltip title="Edit">
                                            <IconButton onClick={() => onEdit(l)}>
                                                <Icon path={mdiPencil} size={1} horizontal vertical rotate={180} color="#EEE" />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <Tooltip title="Delete">
                                            <IconButton onClick={() => onDelete(l, discipline)}>
                                                <Icon path={mdiDelete} size={1} horizontal vertical rotate={180} color="#EEE" />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemIcon>
                                </ListItem>
                            );
                        })}
                </List>
            </CardContent>
        </Card>
    );
}

export default function RosterEditor() {
    const season = useSelector((s: ReduxState) => s.season);
    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const graduatingYear = new Date().getMonth() >= 7 ? new Date().getFullYear() + 4 : new Date().getFullYear() + 3;

    const { teamId } = useParams<{ teamId: string }>();

    const [teamData, setTeamData] = useState<ITeam | null>(null);
    const [pairTeam, setPairTeam] = useState<ITeam | null>(null);
    const [users, setUsers] = useState<Record<string, IUser> | null>(null);
    const [invites, setInvites] = useState<Record<string, ITeamInvite>>({});
    // const [administratingTeams, setAdministratingTeams] = useState<ITeam[]>([]);
    const [processedRoster, setProcessedRoster] = useState<Record<Weapon, Map<IExistingFencer, { home: number; away: number }>>>({
        Sabre: new Map(),
        Foil: new Map(),
        Epee: new Map()
    });

    const allFencers = Object.values(processedRoster).flatMap(l => [...l.keys()]);

    const possibleSeasons = ["2023-2024", "2024-2025"];
    const availableSeasons = teamData ? possibleSeasons.filter(l => !Object.keys(teamData.roster || {}).includes(l)) : [...possibleSeasons];

    const [username, setUsername] = useState("");
    const [lastname, setLastname] = useState("");
    const [graduationYear, setGraduationYear] = useState<number | undefined>(graduatingYear);
    const [formWeapon, setFormWeapon] = useState<Weapon | "">("");
    const [seasonToClone, setSeasonToClone] = useState("Don't clone data");
    const [newSeason, setNewSeason] = useState(availableSeasons?.[0] || "No available seasons!");

    const [addingUser, setAddingUser] = useState(false);
    const [addingFencer, setAddingFencer] = useState<{ label: string; data: IExistingFencer } | null>(null);
    const [creatingUser, setCreatingUser] = useState<null | true>(null);
    const [editingUser, setEditingUser] = useState<IExistingFencer | null>(null);
    const [editingWeapon, setEditingWeapon] = useState<Weapon | null>(null);
    const [mergingFencer, setMergingFencer] = useState<IExistingFencer | null>(null);
    const [mergingWeapon, setMergingWeapon] = useState<Weapon | null>(null);
    const [mergingFencerTarget, setMergingFencerTarget] = useState<{ label: string; id: string } | null>(null);
    const [deletingUser, setDeletingUser] = useState<IExistingFencer | null | false>(null);
    const [deletingWeapon, setDeletingWeapon] = useState<Weapon | null>(null);
    const [savingChanges, setSavingChanges] = useState(false);
    const [cancellingChanges, setCancellingChanges] = useState(false);
    const [cloningSeason, setCloningSeason] = useState(false);
    const [deletingSeason, setDeletingSeason] = useState(false);
    const [seasonToDelete, setSeasonToDelete] = useState("");
    const [deletingTeam, setDeletingTeam] = useState(false);
    const [leavingTeam, setLeavingTeam] = useState(false);
    const [linkingFencer, setLinkingFencer] = useState<IExistingFencer | null>(null);
    const [linkingUser, setLinkingUser] = useState<{ label: string; id: string } | null>(null);
    const [configuringFencer, setConfiguringFencer] = useState<IExistingFencer | null>(null);
    const [configuringFencerUID, setConfiguringFencerUID] = useState<string | null>(null);
    const [configuringUser, setConfiguringUser] = useState<IUser | null>(null);
    const [importingRoster, setImportingRoster] = useState(false);
    const [exportingRoster, setExportingRoster] = useState(false);
    const [importingFile, setImportingFile] = useState<File | null>(null);
    const [configuringManagers, setConfiguringManagers] = useState(false);
    const [savingStatus, setSavingStatus] = useState("");

    const [actionQueue, setActionQueue] = useState<ActionQueue>([]);
    const actionQueueLength = useRef(0);
    actionQueueLength.current = actionQueue.length;

    const [alertOpen, setAlertOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const [criticalError, setCriticalError] = useState("");
    const [snackbarError, setSnackbarError] = useState("");
    const [csvError, setCSVError] = useState("");

    const dispatch = useDispatch();
    const history = useHistory();
    const DB = useDatabase();

    const administrators = (teamData?.administrators || []).map(l => users?.[l]).filter(Boolean);

    const [availableFencers, setAvailableFencers] = useState<IExistingFencer[]>([]);

    const disabled = !username || !lastname || !graduationYear || parseInt(graduationYear.toString()) !== graduationYear;

    const alertUser = e => {
        if (actionQueueLength.current) {
            e.preventDefault();
            e.returnValue = "All changes will be lost. Would you like to cancel your changes?";
        }
    };

    const handleUsers = (users: DBResult<Record<string, IUser>>) => {
        if (users.status === "fail") return setSnackbarError(users.data);
        setUsers(users.data);
    };

    useEffect(() => {
        if (!userInfo) {
            return;
        }
        if (userInfo.teams) {
            Promise.all(userInfo.teams.map(l => DB.getTeam(l))).then(l => /*setAdministratingTeams(l.filter(Boolean) as ITeam[])*/ {});
        }
        DB.getUserList(handleUsers);
        DB.getInvitesForTeam(teamId, setInvites);
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
            DB.stopListeningUserList();
            // DB.stopListeningInvites(setInvites);
        };
    }, []);

    const handleTeamData = async (result: DBResult<ITeam>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        const { data } = result;
        if (!data.seasons.length) {
            setCloningSeason(true);
        }

        const roster = data.roster?.[season] || {
            Sabre: {},
            Foil: {},
            Epee: {}
        };
        const rosterMap = {
            Sabre: new Map(),
            Foil: new Map(),
            Epee: new Map()
        };
        const rosterExpanded = await Promise.all([
            Promise.all(Object.keys(roster.Sabre || {}).map(l => DB.getFencer(l))),
            Promise.all(Object.keys(roster.Foil || {}).map(l => DB.getFencer(l))),
            Promise.all(Object.keys(roster.Epee || {}).map(l => DB.getFencer(l)))
        ]);
        for (const fencer of rosterExpanded[0]) {
            if (fencer.status === "fail") continue;
            rosterMap.Sabre.set(fencer.data, roster.Sabre[fencer.data.id]);
        }
        for (const fencer of rosterExpanded[1]) {
            if (fencer.status === "fail") continue;
            rosterMap.Foil.set(fencer.data, roster.Foil[fencer.data.id]);
        }
        for (const fencer of rosterExpanded[2]) {
            if (fencer.status === "fail") continue;
            rosterMap.Epee.set(fencer.data, roster.Epee[fencer.data.id]);
        }
        const availables = data.fencers || [];
        const resolvedAvailables = await Promise.all(availables.map(l => DB.getFencer(l)));
        // TODO: we are gonna refactor this guys
        const trulyAvailable = resolvedAvailables.filter(isSuccess).map(l => l.data);
        setAvailableFencers(trulyAvailable);
        setProcessedRoster(rosterMap);
        setTeamData(data);
        DB.getOrganizationFromTeam(data.id).then(result => {
            if (result.status === "fail") return;
            const org = result.data;
            const idArr: string[] = [];
            if (org.boysTeam && org.boysTeam !== teamId) {
                DB.getTeam(org.boysTeam).then(team => {
                    if (team.status === "fail") return setCriticalError(team.data);
                    return setPairTeam(team.data);
                });
            }
            if (org.girlsTeam && org.girlsTeam !== teamId) {
                DB.getTeam(org.girlsTeam).then(team => {
                    if (team.status === "fail") return setCriticalError(team.data);
                    return setPairTeam(team.data);
                });
            }
        });
        setLoading(false);
    };

    useEffect(() => {
        DB.getTeam(teamId, { listener: handleTeamData });

        return () => DB.stopListeningTeam(teamId);
    }, [season]);

    useEffect(() => {
        if (!availableSeasons.includes(newSeason)) {
            setNewSeason(availableSeasons?.[0] || "No available seasons!");
        }
    }, [availableSeasons]);

    useEffect(() => {
        if (!users || !configuringFencer) return;

        const matchingUser = Object.values(users).find(l => l.linkedFencerIds.includes(configuringFencer.id));
        setConfiguringUser(matchingUser || null);
        setConfiguringFencerUID(matchingUser?.id || null);
    }, [users, configuringFencer]);

    if (loading || !teamData) {
        return (
            <TbTPage>
                <CommonLoading color="#714FCA" size="large" />
            </TbTPage>
        );
    }

    const updateStrip = (l: IExistingFencer, home: boolean, weapon: Weapon, strip: number) => {
        const homeStr = home ? "home" : "away";
        processedRoster[weapon].forEach((fencerStrip, fencer) => {
            if (strip === fencerStrip[homeStr]) {
                processedRoster[weapon].set(fencer, {
                    ...fencerStrip,
                    [homeStr]: 0
                });
                setActionQueue(aq => [
                    ...aq,
                    {
                        type: "updateStrip",
                        id: fencer.id,
                        season,
                        weapon,
                        home,
                        strip: 0
                    }
                ]);
            }
        });

        if (!l.id) return;

        const prevStrip = processedRoster[weapon].get(l) || {
            home: 0,
            away: 0
        };
        processedRoster[weapon].set(l, { ...prevStrip, [homeStr]: strip });
        setProcessedRoster({ ...processedRoster });
        setActionQueue(aq => [...aq, { type: "updateStrip", id: l.id, season, weapon, home, strip }]);
    };

    //#region Adding fencer
    const addFencer = async (fencer: IExistingFencer) => {
        if (formWeapon === "") {
            setAlertOpen(true);
            return;
        }

        setActionQueue([...actionQueue, { type: "addFencer", id: fencer.id, season, weapon: formWeapon }]);
        processedRoster[formWeapon].set(fencer, { home: 0, away: 0 });
        setProcessedRoster({ ...processedRoster });
        setAddingUser(false);
    };
    //#endregion Adding fencer

    //#region Creating fencer
    const createFencer = async () => {
        if (formWeapon === "") return;

        const id = `newFencer${Math.random()}`;

        const newFencer: IExistingFencer = {
            bouts: [],
            createdAt: Date.now(),
            createdBy: userInfo!.id,
            firstName: username,
            lastName: lastname,
            gradYear: graduationYear,
            id,
            pin: 0,
            teams: [],
            updatedAt: Date.now()
        };

        setAvailableFencers(list => [...list, { ...newFencer }]);
        setActionQueue(aq => [
            ...aq,
            {
                type: "createFencer",
                id,
                firstName: username,
                lastName: lastname,
                graduationYear,
                season,
                weapon: formWeapon
            }
        ]);
        processedRoster[formWeapon].set(newFencer, { home: 0, away: 0 });
        setProcessedRoster({ ...processedRoster });
        setCreatingUser(null);
        setUsername("");
        setLastname("");
    };
    //#endregion Creating fencer

    //#region Deleting fencer
    const deleteFencer = async () => {
        if (!deletingUser || !deletingWeapon) return;

        setActionQueue(aq => [
            ...aq,
            {
                type: "deleteFencer",
                id: deletingUser.id,
                season,
                weapon: deletingWeapon
            }
        ]);
        processedRoster[deletingWeapon].delete(deletingUser);
        setProcessedRoster({ ...processedRoster });
        setDeletingUser(null);
        setDeletingWeapon(null);
    };

    const DeleteFencerModal = () => {
        return (
            <Modal
                open={!!deletingUser}
                onClose={() => {
                    setDeletingUser(false);
                    setDeletingWeapon(null);
                }}
            >
                <Box sx={modalBoxStyle}>
                    <h2>Are you sure you want to remove this fencer from the roster?</h2>
                    <Button variant="contained" color="error" onClick={deleteFencer}>
                        Delete fencer
                    </Button>
                </Box>
            </Modal>
        );
    };
    //#endregion Deleting fencer

    //#region Editing fencer
    const startEditing = (weapon: Weapon) => (v: IExistingFencer) => {
        setEditingUser(v);
        setUsername(v.firstName);
        setLastname(v.lastName);
        setGraduationYear(v.gradYear);
        setEditingWeapon(weapon);
    };

    const editFencer = async () => {
        if (!editingUser || !editingWeapon) return;

        editingUser.firstName = username;
        editingUser.lastName = lastname;
        editingUser.gradYear = graduationYear;
        setActionQueue(aq => [
            ...aq,
            {
                type: "editFencer",
                id: editingUser.id,
                firstName: editingUser.firstName,
                lastName: editingUser.lastName,
                graduationYear: editingUser.gradYear,
                season,
                weapon: editingWeapon
            }
        ]);
        setEditingUser(null);
        setUsername("");
        setLastname("");
    };
    //#endregion Editing fencer

    //#region Merging fencer

    const mergeFencer = async () => {
        if (!mergingFencer || !mergingFencerTarget?.id) return;

        await DB.mergeFencer(mergingFencer.id, mergingFencerTarget.id, teamData || teamId);
        setMergingFencerTarget(null);
        setMergingFencer(null);
    };

    //#endregion Merging fencer

    //#region Cloning season
    const cloneSeason = async () => {
        await DB.createNewClonedSeason(teamId, seasonToClone, newSeason);
        dispatch({ type: "switchSeasonV2", payload: newSeason });
    };

    const deleteSeason = async () => {
        await DB.deleteTeamSeason(teamId, seasonToDelete);
        const newSeason = teamData?.seasons[0] || "2023-2024";
        setSeasonToDelete("");
        dispatch({ type: "switchSeasonV2", payload: newSeason });
    };
    //#endregion Cloning season

    //#region Saving changes
    const saveChanges = async () => {
        try {
            setSavingStatus("Saving changes...");
            await DB.runActionQueue(teamId, actionQueue);
            setActionQueue([]);
            setSavingStatus("");
        } catch (e: unknown) {
            console.error(e);
            setSavingStatus("An error occurred.");
        }
    };

    const SaveChangesModal = () => {
        return (
            <Modal open={!!savingChanges} onClose={() => setSavingChanges(false)}>
                <Box sx={modalBoxStyle}>
                    <h2>All changes will be permanent. Would you like to save your changes?</h2>
                    <div style={{ display: "flex" }}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                saveChanges();
                                setSavingChanges(false);
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                setSavingChanges(false);
                            }}
                        >
                            No
                        </Button>
                    </div>
                    {savingStatus !== "" && (
                        <div
                            style={{
                                position: "absolute",
                                left: "calc(50% - 175px)",
                                zIndex: "10"
                            }}
                        >
                            {alertOpen && (
                                <Alert onClose={() => setAlertOpen(false)}>
                                    <AlertTitle>{savingStatus}</AlertTitle>
                                </Alert>
                            )}
                        </div>
                    )}
                </Box>
            </Modal>
        );
    };
    //#endregion Saving changes

    //#region Canceling changes
    const deleteChanges = () => {
        setActionQueue([]);
        DB.getTeam(teamId).then(handleTeamData);
    };

    const CancelChangesModal = () => {
        return (
            <Modal open={!!cancellingChanges} onClose={() => setCancellingChanges(false)}>
                <Box sx={modalBoxStyle}>
                    <h2>All changes will be lost. Would you like to cancel your changes?</h2>
                    <div style={{ display: "flex" }}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                deleteChanges();
                                setCancellingChanges(false);
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                setCancellingChanges(false);
                            }}
                        >
                            No
                        </Button>
                    </div>
                    {savingStatus !== "" && (
                        <div
                            style={{
                                position: "absolute",
                                left: "calc(50% - 175px)",
                                zIndex: "10"
                            }}
                        >
                            {alertOpen && (
                                <Alert onClose={() => setAlertOpen(false)}>
                                    <AlertTitle>{savingStatus}</AlertTitle>
                                </Alert>
                            )}
                        </div>
                    )}
                </Box>
            </Modal>
        );
    };
    //#endregion Canceling changes

    //#region Linking fencer
    const linkFencer = async (userID: string) => {
        await DB.linkUserToFencer(userID, linkingFencer!.id).then(() => setLinkingFencer(null));
        DB.getUserList().then(handleUsers);
    };
    //#endregion Linking fencer

    //#region Configuring fencer
    const unlinkFencer = async () => {
        if (configuringFencer && users) {
            const linkedUser = Object.values(users).find(l => l.linkedFencerIds.includes(configuringFencer.id));

            if (linkedUser) {
                await DB.linkUserToFencer(linkedUser.id, configuringFencer.id);
                DB.getUserList().then(handleUsers);
            }
        }
    };

    const toggleTeamManager = () => {
        if (teamData && users && configuringFencer) {
            const linkedUser = Object.values(users).find(l => l.linkedFencerIds.includes(configuringFencer.id));
            if (linkedUser) {
                if ((teamData.managers || []).includes(linkedUser.id)) {
                    DB.removeManager(teamId, linkedUser.id);
                } else {
                    DB.addManager(teamId, linkedUser.id);
                }
            }
        }
    };

    const addManager = (id: string) => {
        DB.addManager(teamId, id);
    };

    const removeManager = (id: string) => {
        DB.removeManager(teamId, id);
    };

    const removeAdministrator = (id: string) => {
        DB.removeAdministrator(teamId, id);
    };

    const createInvite = () => {
        DB.inviteAdmin(teamId);
    };

    const deleteInvite = (id: string) => {
        DB.deleteInvite(id);
    };
    //#endregion Configuring fencer

    //#region Export roster
    const exportRoster = async () => {
        if (!teamData) return;
        const csv = await DB.getTeamRosterAsCSV(teamId, season);
        if (csv.status === "fail") setCSVError(csv.data);

        saveAs(new Blob([csv.data]), `${teamData.name} Roster ${season}.csv`);
    };

    const ExportRosterModal = () => {
        return (
            <Modal open={exportingRoster} onClose={() => setExportingRoster(false)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Export roster as CSV
                    </Typography>
                    <h5>
                        By exporting this roster, you will get the roster for this season only exported in CSV format. Note that this does
                        not export personal records or bout data.
                    </h5>
                    <div style={{ display: "flex" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setExportingRoster(false);
                                exportRoster();
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Download as CSV
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                setExportingRoster(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
        );
    };
    //#endregion Export roster

    //#region Import roster
    const downloadTemplate = () => {
        const csv = "First name,Last name,Graduation year,Weapon,Home strip,Away strip";

        saveAs(new Blob([csv]), `TbT Template Roster.csv`);
    };

    const handleImport = async () => {
        const f = importingFile;

        if (!f) return;

        const text = await f.text();

        const parsed = await Papa.parse(text);

        if (parsed.errors.length) {
            return;
        }

        // First name, last name, graduation year, weapon, home strip, away strip
        const data = parsed.data.slice(1) as [string, string, string, string, string, string][];

        const csv: RosterCSV = [];

        const parseStrip = (s: string) => Number(s) || 0;

        for (const row of data) {
            if (!row[3]) continue;
            const existsFencerWithHomeStrip = [...processedRoster[row[3] as Weapon].values()].some(l => l.home === parseStrip(row[4]));
            const existsFencerWithAwayStrip = [...processedRoster[row[3] as Weapon].values()].some(l => l.away === parseStrip(row[4]));

            csv.push({
                firstName: row[0],
                lastName: row[1],
                gradYear: Number(row[2]) || 0,
                weapon: row[3] as Weapon,
                homeStrip: existsFencerWithHomeStrip ? 0 : parseStrip(row[4]),
                awayStrip: existsFencerWithAwayStrip ? 0 : parseStrip(row[5])
            });
        }

        DB.importSeason(teamId, season, csv);
    };
    //#endregion Import roster

    //#region Delete team
    const deleteTeam = async () => {
        const succ = await DB.deleteTeam(teamId);

        if (succ) {
            const userInfo = await DB.getCurrentUserInfo();
            dispatch({ type: "setUserInfoV2", payload: userInfo });
            history.push("/teamList");
        }
    };

    const DeleteTeamModal = () => {
        return (
            <Modal open={deletingTeam} onClose={() => setDeletingTeam(false)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Delete team
                    </Typography>
                    <h5>
                        Delete your team and roster. <strong>Warning: This action is irreversible!</strong>
                    </h5>
                    <div style={{ display: "flex" }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                deleteTeam();
                                setDeletingTeam(false);
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                setDeletingTeam(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
        );
    };
    //#endregion Delete team

    //#region Leave team
    const leaveTeam = async () => {
        const succ = await DB.leaveTeam(teamId);

        if (succ) {
            const userInfo = await DB.getCurrentUserInfo();
            dispatch({ type: "setUserInfoV2", payload: userInfo });
            history.push("/teamList");
        }
    };

    const LeaveTeamDialog = () => {
        return (
            <Dialog open={leavingTeam} onClose={() => setLeavingTeam(false)}>
                <DialogTitle>Leave team</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Would you like to stop being a coach for {teamData?.name || "this team"}? This action is irreversible!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLeavingTeam(false)}>Cancel</Button>
                    <Button onClick={leaveTeam} variant="contained">
                        Leave
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
    //#endregion

    const isAdmin = (userInfo?.flags || 0) & UserFlag.UberAdmin || userInfo?.teams?.includes(teamId);
    const allowedToManageTeam = isAdmin;

    if (!allowedToManageTeam) {
        return <PermissionDenied message="You are not allowed to administrate this team!" />;
    }

    const topTeamInDropdown = pairTeam ? (teamData.name.includes("Men's") ? teamData : pairTeam) : teamData;
    const bottomTeamInDropdown = teamData.name.includes("Women's") ? teamData : pairTeam;
    const teamSwitcherTeams = [topTeamInDropdown, bottomTeamInDropdown].filter(Boolean).map(l => ({ id: l!.id, name: l!.name }));

    const displayedUsers =
        users &&
        Object.values(users)
            .filter(l => (l.flags || 0) & 1)
            .filter(l => !teamData?.managers?.includes(l.id));
    displayedUsers?.sort((a, b) => a.lastName.localeCompare(b.lastName));

    const mergingOptions = mergingWeapon === null ? [] : [...processedRoster[mergingWeapon].keys()];

    return (
        <TbTPage>
            <div style={{ textAlign: "center", marginTop: 25 }}>
                <Button variant="contained" style={{ margin: "15px 7px" }} disabled={!season} onClick={() => setCreatingUser(true)}>
                    Add New Fencer
                </Button>
                <Button variant="contained" style={{ margin: "15px 7px" }} disabled={!season} onClick={() => setAddingUser(true)}>
                    Add Existing Fencer
                </Button>
                <Button variant="contained" style={{ margin: "15px 7px" }} onClick={() => setCloningSeason(true)}>
                    Create New Season
                </Button>
                <Button variant="outlined" color="error" style={{ margin: "15px 7px" }} onClick={() => setDeletingSeason(true)}>
                    Delete Season
                </Button>
                {allowedToManageTeam ? (
                    userInfo?.id !== teamData?.createdBy ? (
                        <Button variant="outlined" color="error" style={{ margin: "15px 7px" }} onClick={() => setLeavingTeam(true)}>
                            Leave Team
                        </Button>
                    ) : (
                        <Button variant="outlined" color="error" style={{ margin: "15px 7px" }} onClick={() => setDeletingTeam(true)}>
                            Delete Team
                        </Button>
                    )
                ) : null}
            </div>
            <div style={{ textAlign: "center" }}>
                <Button
                    variant="contained"
                    color="success"
                    disabled={actionQueue.length === 0}
                    style={{ margin: "0 7px" }}
                    onClick={() => {
                        setSavingChanges(true);
                    }}
                >
                    Save Changes
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    disabled={actionQueue.length === 0}
                    style={{ margin: "0 7px" }}
                    onClick={() => setCancellingChanges(true)}
                >
                    Discard Changes
                </Button>
                <Button
                    variant="contained"
                    color="warning"
                    disabled={season === undefined}
                    style={{ margin: "0 7px" }}
                    onClick={() => setExportingRoster(true)}
                >
                    Export Roster to CSV
                </Button>
                <Button
                    variant="contained"
                    color="warning"
                    disabled={season === undefined}
                    style={{ margin: "0 7px" }}
                    onClick={() => setImportingRoster(true)}
                >
                    Import Roster from CSV
                </Button>
                <Button variant="contained" disabled={!isAdmin} style={{ margin: "0 7px" }} onClick={() => setConfiguringManagers(true)}>
                    Set Coaches
                </Button>
            </div>
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <SeasonSwitcher teamID={teamId}></SeasonSwitcher>
                {topTeamInDropdown && <TeamSwitcher route="/rosterEditor" selectedName={teamData.name} teams={teamSwitcherTeams} />}
            </Box>
            {loading ? (
                <CommonLoading color="#714FCA" size="large" />
            ) : (
                <>
                    <FencersSection
                        users={users || {}}
                        onConfigure={setConfiguringFencer}
                        onLink={setLinkingFencer}
                        updateStrip={updateStrip}
                        onEdit={startEditing("Sabre")}
                        onMerge={v => {
                            setMergingWeapon("Sabre");
                            setMergingFencer(v);
                        }}
                        onDelete={(u, w) => {
                            setDeletingUser(u);
                            setDeletingWeapon(w);
                        }}
                        teamMembers={processedRoster.Sabre}
                        discipline="Sabre"
                    ></FencersSection>
                    <FencersSection
                        users={users || {}}
                        onConfigure={setConfiguringFencer}
                        onLink={setLinkingFencer}
                        updateStrip={updateStrip}
                        onEdit={startEditing("Foil")}
                        onMerge={v => {
                            setMergingWeapon("Foil");
                            setMergingFencer(v);
                        }}
                        onDelete={(u, w) => {
                            setDeletingUser(u);
                            setDeletingWeapon(w);
                        }}
                        teamMembers={processedRoster.Foil}
                        discipline="Foil"
                    ></FencersSection>
                    <FencersSection
                        users={users || {}}
                        onConfigure={setConfiguringFencer}
                        onLink={setLinkingFencer}
                        updateStrip={updateStrip}
                        onEdit={startEditing("Epee")}
                        onMerge={v => {
                            setMergingWeapon("Epee");
                            setMergingFencer(v);
                        }}
                        onDelete={(u, w) => {
                            setDeletingUser(u);
                            setDeletingWeapon(w);
                        }}
                        teamMembers={processedRoster.Epee}
                        discipline="Epee"
                    ></FencersSection>
                </>
            )}
            <Modal open={addingUser} onClose={() => setAddingUser(false)}>
                <Box sx={modalBoxStyle}>
                    <div
                        style={{
                            position: "absolute",
                            left: "calc(50% - 175px)",
                            zIndex: "10"
                        }}
                    >
                        {alertOpen && (
                            <Alert severity="error" onClose={() => setAlertOpen(false)}>
                                <AlertTitle>Fencer addition failed</AlertTitle>
                                You need to set a weapon to assign this fencer to!
                            </Alert>
                        )}
                    </div>
                    <Box>
                        <Typography variant="h4" fontFamily="Lexend Deca">
                            Add existing fencer
                        </Typography>
                        <Autocomplete
                            id="find-fencer-autocomplete"
                            sx={{ minWidth: 200, marginTop: "20px" }}
                            value={addingFencer}
                            onChange={(_, v) => setAddingFencer(v)}
                            renderInput={params => <TextField {...params} label="Find fencer" />}
                            options={availableFencers.map(l => ({ label: `${l.firstName} ${l.lastName} - ${l.gradYear}`, data: l }))}
                        />
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-end",
                                margin: "0 10px 10px 10px"
                            }}
                        >
                            <p style={{ margin: "0 10px 0 0" }}>Add fencer to: </p>

                            <FormControl variant="standard">
                                <InputLabel id="add-fencer-to-label">Weapon</InputLabel>
                                <Select
                                    labelId="add-fencer-to-label"
                                    sx={{ width: 100 }}
                                    label="Weapon"
                                    value={formWeapon}
                                    onChange={e => setFormWeapon(e.target.value as Weapon)}
                                >
                                    <MenuItem value="Sabre">Sabre</MenuItem>
                                    <MenuItem value="Foil">Foil</MenuItem>
                                    <MenuItem value="Epee">Epee</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <Button disabled={!addingFencer?.data} onClick={() => addingFencer && addFencer(addingFencer!.data)}>
                            Add
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal open={!!creatingUser} onClose={() => setCreatingUser(null)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h5" fontFamily="Lexend Deca">
                        Create new fencer
                    </Typography>
                    <FormControl style={{ marginTop: 20, width: "100%" }}>
                        <TextField
                            label="First Name"
                            placeholder="First Name"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <FormControl style={{ marginTop: 20, width: "100%" }}>
                        <TextField
                            label="Last Name"
                            placeholder="Last Name"
                            value={lastname}
                            onChange={e => setLastname(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <FormControl style={{ marginTop: 20, width: "100%" }}>
                        <TextField
                            label="Graduation Year"
                            placeholder="Graduation Year"
                            value={graduationYear}
                            onChange={e => setGraduationYear(Number(e.target.value))}
                            variant="filled"
                            type="number"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <FormControl style={{ marginTop: 20, display: "block" }}>
                        <InputLabel id="weapon-dropdown-label">Weapon</InputLabel>
                        <Select
                            sx={{ width: 150 }}
                            label="Weapon"
                            value={formWeapon}
                            onChange={e => setFormWeapon(e.target.value as Weapon)}
                        >
                            <MenuItem value="Sabre">Sabre</MenuItem>
                            <MenuItem value="Foil">Foil</MenuItem>
                            <MenuItem value="Epee">Epee</MenuItem>
                        </Select>
                    </FormControl>

                    {processedRoster[formWeapon] &&
                        Array.from(processedRoster[formWeapon].entries()).find(
                            ([fencer, _]) =>
                                fencer.firstName === username && fencer.lastName === lastname && fencer.gradYear === graduationYear
                        ) && (
                            <Alert severity="warning" sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                                A fencer with the same information is already present. If this is intentional, you may ignore this message.
                            </Alert>
                        )}

                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            createFencer();
                        }}
                        className={`${disabled || formWeapon === "" || !username.trim() || !lastname.trim() ? "disabledButton" : ""}`}
                        disabled={disabled || formWeapon === "" || !username.trim() || !lastname.trim()}
                        style={{ marginTop: 15 }}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>
            <Modal open={!!editingUser} onClose={() => setEditingUser(null)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Edit fencer
                    </Typography>
                    <FormControl style={{ marginTop: 20, width: "100%" }}>
                        <TextField
                            label="First Name"
                            placeholder="First Name"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <FormControl style={{ marginTop: 20, width: "100%" }}>
                        <TextField
                            label="Last Name"
                            placeholder="Last Name"
                            value={lastname}
                            onChange={e => setLastname(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <FormControl style={{ marginTop: 20, width: "100%" }}>
                        <TextField
                            label="Graduation Year"
                            placeholder="Graduation Year"
                            value={graduationYear}
                            onChange={e => setGraduationYear(Number(e.target.value))}
                            variant="filled"
                            type="number"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <Button
                        variant="contained"
                        color="success"
                        className={`${disabled || !username.trim() || !lastname.trim() ? "disabledButton" : ""}`}
                        disabled={disabled || !username.trim() || !lastname.trim()}
                        style={{ marginTop: 15 }}
                        onClick={editFencer}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>
            <Modal
                open={Boolean(mergingFencer)}
                onClose={() => {
                    setMergingFencer(null);
                    setMergingFencerTarget(null);
                }}
            >
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Merge fencer
                    </Typography>

                    <Typography sx={{ marginTop: "20px" }}>
                        Merge {mergingFencer?.firstName} {mergingFencer?.lastName}'s statistics and bouts with those of another{" "}
                        {mergingWeapon} fencer on the team. This action will remove {mergingFencer?.firstName} {mergingFencer?.lastName} and
                        add their bouts to the other fencer's record.
                    </Typography>

                    <Autocomplete
                        id="simple-select-helper"
                        sx={{ minWidth: 200, marginTop: "10px" }}
                        value={mergingFencerTarget}
                        onChange={(_, v) => setMergingFencerTarget(v)}
                        renderInput={params => <TextField {...params} label="Target Fencer" />}
                        options={mergingOptions
                            .filter(l => l.id !== mergingFencer?.id)
                            .map(l => ({ label: `${l.firstName} ${l.lastName}`, id: l.id }))}
                    />

                    <Button
                        variant="contained"
                        color="success"
                        className={`${!mergingFencerTarget ? "disabledButton" : ""}`}
                        disabled={!mergingFencerTarget}
                        style={{ marginTop: 15 }}
                        onClick={mergeFencer}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>
            <DeleteFencerModal />
            <SaveChangesModal />
            <CancelChangesModal />
            <Modal open={!!cloningSeason} onClose={() => setCloningSeason(false)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Create new season
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            margin: "10px 0 20px 0",
                            flexWrap: "wrap"
                        }}
                    >
                        <div style={{ marginRight: 10 }}>
                            <p style={{ marginBottom: 15 }}>Select new season</p>

                            <FormControl>
                                <InputLabel id="new-season-label">New season</InputLabel>
                                <Select
                                    sx={{ width: 150 }}
                                    labelId="new-season-label"
                                    label="New season"
                                    value={newSeason}
                                    onChange={e => setNewSeason(e.target.value as Weapon)}
                                >
                                    {availableSeasons.map(l => (
                                        <MenuItem key={`oexzcvrpoa${l}`} value={l}>
                                            {l}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <p style={{ marginBottom: 15 }}>Select season to clone</p>

                            <FormControl>
                                <InputLabel id="season-to-clone-label">Season to clone</InputLabel>
                                <Select
                                    labelId="season-to-clone-label"
                                    id="season-to-clone"
                                    sx={{ width: 170 }}
                                    label="Season to clone"
                                    value={seasonToClone}
                                    onChange={e => setSeasonToClone(e.target.value as Weapon)}
                                >
                                    <MenuItem key={`oexzcvrcvxpoa$`} value="Don't clone data">
                                        Don't clone data
                                    </MenuItem>
                                    {Object.keys(teamData.roster || {}).map(l => (
                                        <MenuItem key={`oexzcvrcvxpoa${l}`} value={l}>
                                            {l}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Button
                            variant="contained"
                            disabled={newSeason === "No available seasons!"}
                            color="success"
                            onClick={() => {
                                setCloningSeason(false);
                                cloneSeason();
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                setCloningSeason(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
            <Modal open={!!deletingSeason} onClose={() => setDeletingSeason(false)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Delete season
                    </Typography>
                    <div style={{ margin: "20px 0" }}>
                        <p style={{ marginBottom: 15 }}>
                            Delete a season from the roster. Note: <strong>This action is irreversible!</strong>
                        </p>

                        <FormControl>
                            <InputLabel id="new-season-label">Season to delete</InputLabel>
                            <Select
                                sx={{ width: 200 }}
                                labelId="new-season-label"
                                label="Season to delete"
                                value={seasonToDelete}
                                onChange={e => setSeasonToDelete(e.target.value)}
                            >
                                {(teamData?.seasons || []).map(l => (
                                    <MenuItem key={`oexzcvrpoa${l}`} value={l}>
                                        {l}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Button
                            variant="contained"
                            disabled={seasonToDelete === ""}
                            color="error"
                            onClick={() => {
                                setDeletingSeason(false);
                                deleteSeason();
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                setDeletingSeason(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
            <DeleteTeamModal />
            <Modal open={!!linkingFencer} onClose={() => setLinkingFencer(null)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Link fencer to user
                    </Typography>
                    <Autocomplete
                        sx={{ margin: "15px 0" }}
                        value={linkingUser}
                        onChange={(_, v) => setLinkingUser(v as { label: string; id: string } | null)}
                        renderInput={params => <TextField {...params} label="Find user" />}
                        options={(displayedUsers || ([] as IUser[])).map(l => ({
                            label: `${l.firstName} ${l.lastName}`.trim() || l.email,
                            id: l.id
                        }))}
                        filterOptions={createFilterOptions({
                            matchFrom: "start",
                            stringify: option => option.label
                        })}
                        freeSolo={false}
                    />
                    <Button disabled={!linkingUser?.id} onClick={() => linkFencer(linkingUser!.id)}>
                        Link
                    </Button>
                </Box>
            </Modal>
            <Modal open={!!configuringFencer} onClose={() => setConfiguringFencer(null)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Configure linked fencer settings
                    </Typography>
                    {configuringUser && configuringFencer ? (
                        <Typography variant="body1" fontFamily="Roboto" sx={{ marginTop: "10px" }}>
                            Fencer {configuringFencer.firstName} {configuringFencer.lastName} is linked to{" "}
                            <strong>
                                {configuringUser.firstName} {configuringUser.lastName}'s
                            </strong>{" "}
                            ({configuringUser.email}) account.
                        </Typography>
                    ) : null}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(teamData?.managers || []).includes(configuringFencerUID || "asdf")}
                                onChange={toggleTeamManager}
                            />
                        }
                        label="User is team manager?"
                        labelPlacement="end"
                    />
                    <p style={{ color: "#777", fontSize: 14 }}>
                        Team managers are students who are allowed to edit and manage the roster and dual meets of a team.
                    </p>
                    <div style={{ display: "flex" }}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                setConfiguringFencer(null);
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                unlinkFencer();
                                setConfiguringFencer(null);
                            }}
                        >
                            Unlink fencer
                        </Button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={!!importingRoster}
                onClose={() => {
                    setImportingFile(null);
                    setImportingRoster(false);
                }}
            >
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Import roster
                    </Typography>
                    <Typography style={{ color: "#AAA", fontSize: 14, margin: "10px 0" }}>
                        If you have exported a team from the 2020-2021 or 2021-2022 seasons on the legacy version of TbT, the associated CSV
                        file can be uploaded and imported here. You can also download the template CSV file and fill in your roster
                        manually.
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<Icon path={mdiUpload} size={1} />}
                        >
                            Upload CSV file
                            <input type="file" accept=".csv" hidden onChange={e => setImportingFile(e.target.files?.[0] || null)} />
                        </Button>
                        {importingFile ? <Typography sx={{ marginLeft: "10px" }}>{importingFile.name}</Typography> : null}
                    </Box>
                    <div style={{ display: "flex", marginTop: "15px" }}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                handleImport();
                                setImportingRoster(false);
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Import
                        </Button>
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={() => {
                                downloadTemplate();
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Download template
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                setImportingFile(null);
                                setImportingRoster(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
            <ExportRosterModal />
            <Modal
                open={configuringManagers}
                onClose={() => {
                    setConfiguringManagers(false);
                }}
            >
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Set Coaches
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 10
                        }}
                    >
                        <div style={{ width: "49%", height: 400 }}>
                            <Typography variant="h5" sx={{ marginBottom: "5px" }}>
                                Invite Coach
                            </Typography>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", margin: "10px 0" }}>
                                <Button variant="contained" onClick={createInvite}>
                                    Create invite code
                                </Button>
                            </Box>
                            <Stack sx={{ overflow: "auto" }}>
                                {Object.values(invites).map(l => (
                                    <InviteCode key={`inviteCode${l.id}`} data={l} deleteInvite={() => deleteInvite(l.id)} />
                                ))}
                            </Stack>
                        </div>
                        <div style={{ width: "49%", height: 400 }}>
                            <Typography variant="h5" sx={{ marginBottom: "5px" }}>
                                Team Coaches
                            </Typography>
                            <Paper>
                                <List style={{ maxHeight: 400, overflow: "auto" }}>
                                    {administrators.map(l =>
                                        l ? (
                                            <ListItem
                                                key={`usasdoj${l.id}`}
                                                secondaryAction={
                                                    <IconButton onClick={() => removeAdministrator(l.id)}>
                                                        <Icon path={mdiDelete} size={1} horizontal vertical rotate={180} color="#FFF" />
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemText>
                                                    {l.firstName} {l.lastName}
                                                </ListItemText>
                                            </ListItem>
                                        ) : null
                                    )}
                                </List>
                            </Paper>
                        </div>
                    </div>
                </Box>
            </Modal>
            <LeaveTeamDialog />
            <RosterAutoUpdateDialog />
        </TbTPage>
    );
}
